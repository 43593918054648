import * as yup from 'yup';
import {
  schemaFullName,
  schemaBoolRequiredSocialName,
  schemaStringDateRequired,
  schemaNumberGenrer,
  schemaCepRequired,
  schemaStringRequired,
  schemaNoNumber,
  schemaStringPhoneRequired,
  schemaPasswordRequired,
  schemaCheckPasswordRequired,
  schemaStringCpfRequired,
  schemaSocialName,
  schemaEmailRequired,
  schemaCheckEmailRequired
} from '../SchemaFieldsRequired';

export interface FormUpdateUserDataValues {
  code?: string;
  currentPassword: string;
  email: string;
  checkEmail: string;
  password: string;
  checkPassword: string;
  userName: string;
  socialName: string;
  cellPhoneNumber: string;
  hasSocialName: boolean;
  birthDate: string;
  gender: string;
  kindGender: string;
  cpf?: string;
  cep?: string;
  state: string;
  city: string;
  neighborhood?: string;
  streetName?: string;
  addressNumber?: string;
  hasAddressNumber?: boolean;
  complement?: string;
  isSocial?: boolean;
}

export function createForm() {
  const objectFields = {
    code: yup.string(),
    currentPassword: schemaPasswordRequired,
    email: schemaEmailRequired,
    checkEmail: schemaCheckEmailRequired,
    password: schemaPasswordRequired,
    checkPassword: schemaCheckPasswordRequired,
    userName: schemaFullName,
    socialName: schemaSocialName,
    hasSocialName: schemaBoolRequiredSocialName,
    cellPhoneNumber: schemaStringPhoneRequired,
    birthDate: schemaStringDateRequired,
    gender: yup.string(),
    kindGender: schemaNumberGenrer,
    cpf: schemaStringCpfRequired,
    cep: schemaCepRequired,
    state: schemaStringRequired,
    city: schemaStringRequired,
    neighborhood: schemaStringRequired,
    streetName: schemaStringRequired,
    addressNumber: schemaNoNumber,
    hasAddressNumber: yup.bool(),
    complement: yup.string()
  };

  return yup.object(objectFields);
}
