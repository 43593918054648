import { createElement, useCallback, useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import Wizard from '../../components/Wizard/Wizard';
import SignUp from '../SignUp';
import PersonalData from '../PersonalData';
import Step from '../../components/Wizard/Step';
import './SignUpNativo.scss';
import SignUpProvider, { SignUpContext } from '../../data/SignUp/SignUpContext';
import { useMemo } from 'react';
import PhoneNumber from '../PhoneNumber';
import SuccessSignUp from '../SuccessSignUp';
import EmailPage from '../Email';
import { useLocation, useNavigate } from 'react-router-dom';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { IAccountControllerLogin } from '../../interfaces/ISocial';
import SuccessMerge from '../SuccessMerge';
import { useAuthContext } from '../../hooks/AuthContext';
import ValidateEmail from '../ValidateEmail';
import {
  CTV_SUCCESS_PAGE_PATH,
  ORIGIN_DOMAIN_SBT_TV
} from '../../data/Constants';
import {
  getOriginDomain,
  shouldOpenMenuAccount
} from '../../utils/GenericFunctions';

export default function SignUpNativo() {
  const { setSSORedirect, redirectURL } = useAuthContext();
  const { getOrigin, controllerViews, ctvCode } =
    useControllerFlowStepsContext();
  const { redirectToSite } = useAuthContext();
  const navigate = useNavigate();
  const components: any = {
    SignUp: {
      id: 'criar-conta',
      component: SignUp,
      allowSkipStep: false
    },
    ValidateEmail: {
      id: 'validar-email',
      component: ValidateEmail,
      allowSkipStep: false
    },
    PersonalData: {
      id: 'complemento-dados-pessoais',
      component: PersonalData,
      allowSkipStep: false
    },
    PhoneNumber: {
      id: 'cadastro-telefone',
      component: PhoneNumber,
      allowSkipStep: true
    },
    EmailPage: {
      id: 'cadastro-email',
      component: EmailPage,
      allowSkipStep: false
    },
    SuccessSignUp: {
      id: 'cadastro-concluido',
      component: SuccessSignUp,
      allowSkipStep: false
    },
    SuccessMerge: {
      id: 'cadastro-concluido-merge',
      component: SuccessMerge,
      allowSkipStep: false,
      updateData: false
    }
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [defaultSteps, setDefaultSteps] = useState([
    getComponent('SignUp', false),
    getComponent('ValidateEmail', false),
    getComponent('PhoneNumber', true),
    getComponent('SuccessSignUp', false)
  ]);
  const handleChangeStep = useCallback((indexStep: number) => {
    setCurrentStep(indexStep);
  }, []);

  function getComponent(
    key: string,
    allowSkipStep: boolean,
    updateData?: boolean
  ) {
    const component = components[key];
    component['updateData'] = updateData;
    component['allowSkipStep'] = allowSkipStep;
    return component;
  }

  useEffect(() => {
    if (Object.keys(controllerViews).length) {
      controllerViewsPage(controllerViews);
    }
  }, [controllerViews]);

  function controllerViewsPage(data: IAccountControllerLogin) {
    if (data.mergeAccount) {
      if (ctvCode) return navigate(CTV_SUCCESS_PAGE_PATH);
      if (shouldOpenMenuAccount(redirectURL, { emailValidated: true }))
        return navigate('/menu-de-conta');
      redirectToSite();
    } else {
      const updateSteps = [];

      if (!data.hasEmail) {
        updateSteps.push(getComponent('EmailPage', true));
      }

      // Apenas TV e SBT devem pedir telefone
      if (
        !data.hasTelephone &&
        getOrigin() !== 'videos' &&
        (getOrigin() === '' || getOriginDomain() === ORIGIN_DOMAIN_SBT_TV)
      ) {
        updateSteps.push(getComponent('PhoneNumber', true, true));
      }

      if (updateSteps.length) {
        updateSteps.push(getComponent('SuccessSignUp', false));
        setDefaultSteps(updateSteps);
      } else {
        if (ctvCode) return navigate(CTV_SUCCESS_PAGE_PATH);
        redirectToSite();
      }
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('redirect')) {
      setSSORedirect(query.get('redirect') as string);
    }
    const indexSuccessMerge = defaultSteps.findIndex(
      (step: any) => step.id === 'cadastro-concluido-merge'
    );
    if (Object.keys(controllerViews).length && indexSuccessMerge > -1) {
      setCurrentStep(indexSuccessMerge);
    }
  }, [defaultSteps]);

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.createAccountFromSocial) {
      mountFlowcreateAccountFromSocial();
    }
    if (location?.state?.createAccountFromSocialWithoutEmail) {
      mountFlowcreateAccountFromSocialWithoutEmail();
    }
  }, [location]);

  function mountFlowcreateAccountFromSocial() {
    let updateSteps = [];

    updateSteps = [
      getComponent('PersonalData', false),
      getComponent('PhoneNumber', true),
      getComponent('SuccessSignUp', false)
    ];

    setDefaultSteps(updateSteps);
  }

  function mountFlowcreateAccountFromSocialWithoutEmail() {
    let updateSteps = [];

    updateSteps = [
      getComponent('EmailPage', false),
      getComponent('PersonalData', false),
      getComponent('PhoneNumber', true),
      getComponent('SuccessSignUp', false)
    ];

    setDefaultSteps(updateSteps);
  }

  const steps = useMemo(() => defaultSteps, [defaultSteps]);

  const buildSteps = useCallback(() => {
    const lastIndex = steps.length - 1;
    return steps.map((step, index) => {
      const component = createElement(step.component, {
        previousPath: index > 0 ? index - 1 : null,
        nextPath: index < lastIndex ? index + 1 : null,
        allowSkipStep: step.allowSkipStep,
        updateData: step.updateData,
        handleChangeStep,
        context: SignUpContext
      });
      return (
        <Step id={index} currentStep={currentStep} key={index}>
          {component}
        </Step>
      );
    });
  }, [currentStep, handleChangeStep, steps]);

  return (
    <SignUpProvider>
      <Container className="signup">
        <Card width="400px" minHeight="550px" center>
          <form className="width-full" onSubmit={(e) => e.preventDefault()}>
            <Wizard>{buildSteps()}</Wizard>
          </form>
        </Card>
      </Container>
    </SignUpProvider>
  );
}
