import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import Icon from '../../../../components/Icon/Icon';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useAuthContext } from '../../../../hooks/AuthContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { formatPhone } from '../../../../utils/GenericFunctions';

export default function CardAccountData() {
  const { user } = useAuthContext();
  const {
    setUpdatePhone,
    userHasPassowrd,
    openModalDeletePhone,
    sizeView,
    isFormEqual,
    setshowModalPendingDataToSave,
    openModalPendingDataToSave
  } = useUpdateUserDataContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const navigate = useNavigate();

  function interceptorGoBack() {
    if (isFormEqual.equal) {
      navigate('/menu-de-conta/atualizar-dados', { state: 'refresh' });
    } else {
      setshowModalPendingDataToSave(true);
      openModalPendingDataToSave();
    }
  }

  function configGoBack() {
    setGoBackNavigate({
      path: '/menu-de-conta/atualizar-dados',
      param: { state: 'refresh' },
      condition: interceptorGoBack
    });
  }

  useEffect(() => {
    configGoBack();
  }, [isFormEqual.equal]);

  useEffect(() => {
    if (sizeView[0] <= 576) {
      setShowButton(true);
      setTimeout(() => {
        if (window.location.pathname === '/menu-de-conta/atualizar-dados/conta')
          configGoBack();
      }, 500);
    }
  }, []);

  function getPhoneNumber() {
    const phone = user.cellPhoneNumber;
    if (!phone) return '';
    return formatPhone(phone);
  }

  function redirectAccount(url: string) {
    if (sizeView[0] <= 576) navigate(`../${url}`);
    else navigate(url);
  }

  function redirectUpdatePhone() {
    redirectAccount('cadastrar/telefone');
  }

  function goUpdatePhone() {
    setUpdatePhone(true);
    redirectUpdatePhone();
  }

  return (
    <Card
      width="100%"
      paddingHorizontal="24"
      paddingVertical="24"
      id="accountData"
    >
      <div className="width-full flex flex-column">
        <h2 className="color-text-default mb-16">dados da conta</h2>

        <div className="width-full">
          <div className="flex align-items-center width-full">
            <div className="width-full">
              <span className="md-bold color-text-default">e-mail</span>
              <br />
              <span className="md-bold-700 color-text-default">
                {user.email}
              </span>
            </div>
            <div
              id="update-email-link"
              onClick={() =>
                (window.location.href =
                  '/menu-de-conta/atualizar-dados/alterar-email/autenticar')
              }
            >
              <Icon
                data-testid="edit-email"
                name="editPencil"
                className="mg-horizontal-auto menu-account-btn-icon"
              />
            </div>
          </div>

          <hr className="mt-21 width-full color-hr-default" />
        </div>

        <div className="width-full mt-8">
          <div className="flex align-items-center width-full">
            <div className="width-full">
              <span className="md-bold color-text-default">senha</span>
              <br />
              <span
                className={`${
                  userHasPassowrd ? 'md-bold' : 'md-bold-700'
                } color-text-default`}
              >
                {userHasPassowrd
                  ? '••••••••••••••••••••'
                  : '(sem senha cadastrada)'}
              </span>
            </div>
            <div
              id={
                userHasPassowrd
                  ? 'update-password-link'
                  : 'create-password-link'
              }
              onClick={() =>
                userHasPassowrd
                  ? redirectAccount('alterar-senha')
                  : redirectAccount('criar-senha')
              }
            >
              <Icon
                data-testid="handle-password"
                name={`${userHasPassowrd ? 'editPencil' : 'iconPlus'}`}
                className="mg-horizontal-auto menu-account-btn-icon"
              />
            </div>
          </div>
          <hr className="mt-16 width-full color-hr-default" />
        </div>

        <div className="width-full mt-8">
          <div className="flex align-items-center width-full">
            <div className="width-full">
              <span className="md-bold color-text-default">celular</span>
              <br />
              <span className="md-bold-700 color-text-default">
                {getPhoneNumber() || '(sem número de celular)'}
              </span>
            </div>
            {getPhoneNumber() ? (
              <>
                <div id="edit-phone-link" onClick={goUpdatePhone}>
                  <Icon
                    data-testid="edit-phone"
                    name="editPencil"
                    className="mg-horizontal-auto menu-account-btn-icon"
                  />
                </div>
                <div id="delete-phone-link" onClick={openModalDeletePhone}>
                  <Icon
                    data-testid="delete-phone"
                    name="trashButton"
                    className="ml-16 menu-account-btn-icon"
                  />
                </div>
              </>
            ) : (
              <div id="add-phone-link" onClick={redirectUpdatePhone}>
                <Icon
                  data-testid="add-phone"
                  name="plus"
                  className="menu-account-btn-icon"
                />
              </div>
            )}
          </div>
          <div className="mt-13 flex align-items-center">
            <Icon name="info" className="account-data-info-icon" />
            <span
              className="sm ml-8 color-text-default brake-info-text"
              style={
                {
                  '--break-text-mobile-with': '260px'
                } as React.CSSProperties
              }
            >
              O seu número de celular será utilizado para recuperar sua conta
              caso você esqueça ou perca seu e-mail.
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
}
