import { createContext, useContext, useEffect, useState } from 'react';
import { createForm, FormSignUpValues } from './FormSignUp';
import {
  FieldErrorsImpl,
  useForm,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormResetField,
  UseFormWatch,
  UseFormGetFieldState
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  clearSpecialCharactersFromNumber,
  formatDateToSend
} from '../../utils/GenericFunctions';
import { createUser } from '../../services/user';
import { setCookie } from '../../services/cookieController';
import { sendSocialData } from '../../services/socialNetWorkController';
import { useAuthContext } from '../../hooks/AuthContext';
import { ICreateUserRequest } from '../../interfaces/user/ICreateUser';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';

const KEY_SESSION = process.env.REACT_APP_KEY_SESSION as string;
const KEY_REFRESH_SESSION = process.env.REACT_APP_KEY_REFRESH_SESSION as string;

export interface SignUpContextData {
  register: UseFormRegister<FormSignUpValues>;
  handleSubmit: UseFormHandleSubmit<FormSignUpValues>;
  errors: Partial<FieldErrorsImpl<FormSignUpValues>>;
  touchedFields: Partial<Readonly<any>>;
  trigger: UseFormTrigger<FormSignUpValues>;
  originDomainForm: string | null;
  submitForm(payload: any, callBack: any, provider?: string): void;
  watch: UseFormWatch<FormSignUpValues>;
  getValues: UseFormGetValues<FormSignUpValues>;
  setValue: UseFormSetValue<FormSignUpValues>;
  setFocus: UseFormSetFocus<FormSignUpValues>;
  setError: UseFormSetError<FormSignUpValues>;
  isValid: boolean;
  unregister: UseFormUnregister<FormSignUpValues>;
  resetField: UseFormResetField<FormSignUpValues>;
  getFieldState: UseFormGetFieldState<FormSignUpValues>;
}

export const SignUpContext = createContext({} as SignUpContextData);

const SignUpProvider = ({ children }: any) => {
  const [originDomainForm, setOrigin] = useState<any>('');
  // create Form Fields
  const formFields = createForm();
  const {
    register,
    unregister,
    trigger,
    watch,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    setError,
    resetField,
    getFieldState,
    formState: { errors, touchedFields, isValid }
  } = useForm<FormSignUpValues>({
    mode: 'onChange',
    defaultValues: {
      isSocial: false
    },
    resolver: yupResolver(formFields, { abortEarly: false })
  });
  const { tokenSocial } = useControllerFlowStepsContext();
  const { getSocialProvider } = useAuthContext();

  useEffect(() => {
    const regexOrigin = /videos/g;
    const regexAppOrigin = /sbt/g;
    if (regexOrigin.test(window.location.pathname)) {
      setOrigin('videos/signup');
    } else if (regexAppOrigin.test(window.location.pathname)) {
      setOrigin('sbt/signup');
    } else {
      setOrigin('signup');
    }
  }, []);

  async function submitForm(formValues: any, callBack: any, provider?: string) {
    const valuesSubmit: ICreateUserRequest = {
      userName: formValues.userName?.trim(),
      acceptTermsOfUse: formValues.acceptTermsOfUse,
      birthDate: formatDateToSend(formValues.birthDate),

      email: formValues.email || undefined,
      emailCode: formValues.emailCode || undefined,

      cellPhoneNumber:
        clearSpecialCharactersFromNumber(formValues.cellPhoneNumber) ||
        undefined,

      socialName: formValues?.socialName?.trim() || ''
    };

    if (!provider) {
      valuesSubmit['password'] = formValues.password;
    } else {
      valuesSubmit['provider'] = provider;
      valuesSubmit['providerToken'] = tokenSocial;
    }

    try {
      const response = await createUser(valuesSubmit);

      if (provider)
        await sendSocialData(tokenSocial, provider, formValues.email);

      if (response?.accessToken) {
        setCookie(KEY_SESSION, response.accessToken);
        setCookie(KEY_REFRESH_SESSION, response.refreshToken);
      }
      callBack();
    } catch (error) {
      console.log('submitform error', error);
    }
  }

  return (
    <SignUpContext.Provider
      value={{
        originDomainForm,
        register,
        unregister,
        handleSubmit,
        errors,
        submitForm,
        touchedFields,
        trigger,
        getValues,
        setValue,
        setFocus,
        watch,
        setError,
        isValid,
        resetField,
        getFieldState
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};
export const useSignUpContext = () =>
  useContext<SignUpContextData>(SignUpContext);
export default SignUpProvider;
