import Cookies, { CookieAttributes } from 'js-cookie';

export function setCookie(
  key: string,
  value: string,
  options?: CookieAttributes
): void {
  Cookies.set(key, value, {
    secure: true,
    sameSite: 'strict',
    ...options
  });
}

export function getCookie(value: string): string | undefined {
  return Cookies.get(value);
}

export function deleteCookie(value: string): void {
  return Cookies.remove(value);
}
