import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import FormField from '../../../../components/FormField/FormField';
import FormFieldSelectAutoComplete from '../../../../components/FormField/FormFieldSelectAutoComplete';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { getCep, getCityUs } from '../../../../services/queryController';
import { cepMask } from '../../../../utils/Masks';
const copyForm: any = {};
export default function CardAddressData() {
  const {
    register,
    touchedFields,
    errors,
    setError,
    setValue,
    getValues,
    noNumber,
    setNoNumber,
    sizeView,
    isFormEqual,
    setshowModalPendingDataToSave,
    openModalPendingDataToSave,
    clearErrors
  } = useUpdateUserDataContext();
  const [citys, setCitys] = useState<any>([]);
  const [disabledStateCity, setDisabledStateCity] = useState(true);
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();

  const navigate = useNavigate();

  function interceptorGoBack() {
    if (isFormEqual.equal) {
      navigate('/menu-de-conta/atualizar-dados', { state: 'refresh' });
    } else {
      setshowModalPendingDataToSave(true);
      openModalPendingDataToSave();
    }
  }

  function configGoBack() {
    setGoBackNavigate({
      path: '/menu-de-conta/atualizar-dados',
      param: { state: 'refresh' },
      condition: interceptorGoBack
    });
  }

  useEffect(() => {
    configGoBack();
  }, [isFormEqual.equal]);

  useEffect(() => {
    if (sizeView[0] <= 576) {
      setShowButton(true);
      configGoBack();
    }
  }, []);

  async function getCEP(cep: string) {
    setValue('state', '');
    setValue('city', '');
    setValue('streetName', '');
    setValue('neighborhood', '');
    try {
      const dataAddress = await getCep(cep.replace(/\D/g, ''));
      if (dataAddress.uf && dataAddress.localidade) {
        setDisabledStateCity(true);
        setValue('state', dataAddress.uf, { shouldTouch: true });
        setValue('city', dataAddress.localidade, { shouldTouch: true });
        setValue('streetName', dataAddress.logradouro || undefined, {
          shouldTouch: true
        });
        setValue('neighborhood', dataAddress.bairro || undefined, {
          shouldTouch: true
        });

        clearErrors('city');
        clearErrors('state');
      } else {
        throw new Error();
      }
    } catch (error) {
      setDisabledStateCity(false);
      setError(
        'state',
        { message: 'Requerido', type: 'string' },
        { shouldFocus: true }
      );
      setError(
        'city',
        { message: 'Requerido', type: 'string' },
        { shouldFocus: false }
      );
    }
  }

  function cepValidate(e: any) {
    if (/[0-9]{5}-[0-9]{3}/g.test(e.target.value)) {
      getCEP(e.target.value);
    }
  }

  function changeCep(e: any) {
    cepMask(e);
  }

  function checkNoNumber(e: React.FormEvent<HTMLInputElement>) {
    setValue(
      'addressNumber',
      e.currentTarget.checked ? '' : copyForm.addressNumber
    );
    setValue('hasAddressNumber', !e.currentTarget.checked);
    setNoNumber((state) => !state);

    if (e.currentTarget.checked) clearErrors('addressNumber');
    else
      setError(
        'addressNumber',
        { message: 'Campo obrigatório', type: 'string' },
        { shouldFocus: false }
      );
  }

  async function getCity() {
    const city = getValues('city');
    try {
      const response = await getCityUs(city);
      const formatCitys = response.map((item) => {
        return { data: item, value: item.city + ' - ' + item.uf };
      });
      setCitys(formatCitys);
      if (!formatCitys.length) {
        setValue('state', '');
        setValue('city', '');
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  function selectValue(item: any) {
    if (item) {
      setValue('city', item.data.city);
      setValue('state', item.data.uf);
    } else {
      setValue('city', '');
      setValue('state', '');
    }
    setCitys([]);
  }

  return (
    <Card
      width="100%"
      paddingHorizontal="24"
      paddingVertical="24"
      id="addressData"
    >
      <div className="width-full flex flex-column mb-60">
        <div className="width-full">
          <h2 className="color-text-default mb-16">endereço</h2>
          <div className="mt-13 half-field">
            <div className="address__number">
              <FormField
                label="CEP"
                customClass="field-cep"
                placeholder="digite seu CEP"
                inputMode="numeric"
                name="cep"
                error={errors}
                touchedFields={touchedFields}
                onKeyUp={changeCep}
                onBlur={cepValidate}
                register={register('cep')}
              />
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                rel="noreferrer"
              >
                não sei o meu CEP
              </a>
            </div>
          </div>
          <div className="address__us">
            <FormFieldSelectAutoComplete
              touchedFields={touchedFields}
              error={errors}
              label="cidade"
              placeholder={
                disabledStateCity
                  ? '[preenchimento automático]'
                  : 'digite sua cidade'
              }
              name="city"
              register={register('city', { required: disabledStateCity })}
              items={citys}
              selectValue={selectValue}
              dispatchFunction={getCity}
              disabled={disabledStateCity}
            />
            <FormField
              label="estado"
              name="state"
              register={register('state')}
              placeholder="[preenchimento automático]"
              disabled
            />
          </div>
          <div className="flex half-field">
            <FormField
              label="bairro"
              customClass="width-full"
              error={errors}
              touchedFields={touchedFields}
              placeholder="digite seu bairro"
              name="neighborhood"
              register={register('neighborhood')}
            />
          </div>
          <div className="triple-fields">
            <div>
              <FormField
                label="rua/avenida"
                error={errors}
                touchedFields={touchedFields}
                placeholder="digite sua rua/avenida"
                name="streetName"
                register={register('streetName')}
              />
            </div>
            <div>
              <FormField
                customClass="field-number-address"
                label="número"
                disabled={noNumber}
                error={errors}
                touchedFields={touchedFields}
                placeholder={noNumber ? 'SN' : 'digite seu número'}
                name="addressNumber"
                inputMode="text"
                register={register('addressNumber')}
              />
            </div>
            <div>
              <label htmlFor="checkNoNumber" className="check-box ml-3 mt-6">
                <span className="md white-space-nowrap">sem número</span>

                <input
                  alt="marcar como sem número"
                  type="checkbox"
                  id="checkNoNumber"
                  onClick={checkNoNumber}
                  checked={noNumber}
                />
                <span className="check-box-checked"></span>
              </label>
            </div>
          </div>
          <div className="flex half-field">
            <FormField
              label="complemento (opcional)"
              customClass="width-full"
              placeholder="digite o complemento"
              name="complemento"
              register={register('complement')}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}
