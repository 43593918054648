import React, { useState, useCallback, useImperativeHandle } from 'react';
import './Modal.scss';

interface ModalProps {
  children?: React.ReactNode;
}

export interface ModalHandles {
  openModal: () => void;
  closeModal: () => void;
}

const Modal: React.ForwardRefRenderFunction<ModalHandles, ModalProps> = (
  { children },
  ref
) => {
  const [visible, setVisible] = useState(false);
  const openModal = useCallback(() => {
    setVisible(true);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openModal,
      closeModal
    };
  });

  function closeModal() {
    setVisible(false);
  }

  return (
    <div
      data-testid="modal"
      style={{ display: visible ? 'block' : 'none' }}
      className="modal"
      onClick={closeModal}
    >
      <div className="modal-card" onClick={(e) => e.stopPropagation()}>
        <div className="modal-swipe" onClick={closeModal} />
        {children}
      </div>
    </div>
  );
};

export default React.forwardRef(Modal);
