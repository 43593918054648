import { BrowserEnum } from '../enums/BrowserEnum';
import { OSEnum } from '../enums/OsEnum';

export function convertUserAgentToBrowser(userAgent: string): BrowserEnum {
  if (/MSIE|Trident/.test(userAgent)) {
    return BrowserEnum.ie;
  } else if (/Edg/.test(userAgent)) {
    return BrowserEnum.microsoft;
  } else if (/OPR|Opera/.test(userAgent)) {
    return BrowserEnum.opera;
  } else if (/Chrome/.test(userAgent) && !/Edg|OPR/.test(userAgent)) {
    return BrowserEnum.chrome;
  } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
    return BrowserEnum.safari;
  } else if (/Firefox/.test(userAgent)) {
    return BrowserEnum.firefox;
  } else {
    return BrowserEnum.unknown;
  }
}

export function convertUserAgentToOS(userAgent: string): OSEnum {
  if (/Windows NT/.test(userAgent)) {
    return OSEnum.windows;
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    return OSEnum.ios;
  } else if (/Macintosh|Mac OS X/.test(userAgent)) {
    return OSEnum.mac;
  } else if (/Linux/.test(userAgent) && !/Android/.test(userAgent)) {
    return OSEnum.linux;
  } else if (/Android/.test(userAgent)) {
    return OSEnum.android;
  } else {
    return OSEnum.unknown;
  }
}
