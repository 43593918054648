import Card from '../../../components/Card/Card';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import { useEffect, useRef, useState } from 'react';
import Wizard from '../../../components/Wizard/Wizard';
import Step from '../../../components/Wizard/Step';
import FormField from '../../../components/FormField/FormField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDisableAccountContext } from '../../../data/DisableAccount/DisableAccountContext';
import { useAuthContext } from '../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import { updateAccountInformation } from '../../../services/accountController';
import Icon from '../../../components/Icon/Icon';
import {
  logoutSSO,
  userConfirmPassword,
  checkUserHasPassword
} from '../../../services/user';
import Modal, { ModalHandles } from '../../../components/Modal/Modal';
import ModalTitle from '../../../components/Modal/ModalTitle';
import ModalDescription from '../../../components/Modal/ModalDescription';
import ModalIcon from '../../../components/Modal/ModalIcon';
import { useControlNavBarContext } from '../../../hooks/ControlNavBar';
import { PASS_MAX_LENGTH } from '../../../data/Constants';
import { isWebview } from '../../../utils/GenericFunctions';
import { passwordMask } from '../../../utils/Masks';
import { SentryContextsEnum } from '../../../utils/Sentry';
import { ErrorMessages } from '../../../data/ErrorMessages';

export default function DisableAccount() {
  const { user, setAccountDisabled, setOpenSession, clearSession, checkAuth } =
    useAuthContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const { showErrorMessageServer, setLoadingFullScreen } =
    useControllerFlowStepsContext();
  const { setError, watch, register, setValue, errors, touchedFields } =
    useDisableAccountContext();
  const [valueField, setValueField] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userHasPassword, setUserHasPassword] = useState(false);
  const [showGoBackButton, setShowGoBackButton] = useState(true);
  const [accountHasBeenDisabled, setAccountHasBeenDisabled] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    setShowButton(true);
    getUserInfos();
    setGoBackNavigate({ path: '', param: {} });
    setValueField('');
    setValue('password', '');
    document.querySelector('.container')?.scrollTo?.({ top: 0 });
  }, []);

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueField(data.password);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  window.onpopstate = () => {
    controlBackPage();
  };

  function controlBackPage() {
    if (currentStep == 0) {
      navigate('/menu-de-conta');
    } else if (accountHasBeenDisabled) {
      navigate('/menu-de-conta/excluir-conta', { replace: true });
    } else {
      navigate('/menu-de-conta/excluir-conta');
    }
  }

  async function getUserInfos() {
    setLoadingFullScreen(true);
    try {
      const token = searchParams.get('token') || searchParams.get('jwt');
      const { email } = checkAuth(token || undefined).user;

      if (token) setShowGoBackButton(false);

      const response = await checkUserHasPassword(
        user.email || email,
        SentryContextsEnum.DELETE_ACCOUNT
      );
      setUserHasPassword(response.hasPassword);
    } catch (_) {
      showErrorMessageServer();
      navigate('/menu-de-conta');
    } finally {
      setLoadingFullScreen(false);
    }
  }

  function componentInfosDisableAccount() {
    return (
      <>
        <h2 className="color-text-default mb-4">excluir conta</h2>
        <span className="md color-text-default">
          Você está iniciando o processo de exclusão da sua Conta SBT. Os dados
          vinculados à sua conta não serão mais visíveis, e todos os
          dispositivos logados serão desconectados.
        </span>

        <h2 className="color-text-default mt-16 mb-4">
          informações sobre a exclusão
        </h2>
        <span className="md color-text-default">
          Caso mude de ideia, você tem 30 dias contados a partir da exclusão
          para reativar sua Conta SBT.
        </span>

        <hr className="mt-16 mb-16" />

        <span className="md color-text-default">
          Para reativar a sua conta, basta fazer login com seu e-mail e senha
          atuais e confirmar a reativação.
        </span>

        <hr className="mt-16 mb-16" />

        <span className="md color-text-default">
          Os dados vinculados à sua conta serão guardados por 30 dias contados a
          partir da exclusão da conta. Depois deste período, todos os dados
          serão excluídos permanentemente.
        </span>
        <br />
        <p className="md strong color-text-default mt-16 mb-24">
          A exclusão definitiva da sua conta será analisada pelo responsável de
          dados do SBT.
        </p>

        <div className="disable-account__actions mb-24">
          <Button
            customColors="btn-red"
            className="mbi-0"
            onClick={() => (!userHasPassword ? openModal() : setCurrentStep(1))}
          >
            <ButtonLabel>excluir conta</ButtonLabel>
          </Button>
          {showGoBackButton && (
            <Button
              customColors="btn-white"
              className="mt-16"
              onClick={() => navigate('/menu-de-conta')}
            >
              <ButtonLabel>voltar</ButtonLabel>
            </Button>
          )}
        </div>
      </>
    );
  }

  function componentPasswordDisableAccount() {
    return (
      <div className="width-full">
        <h2 className="color-text-default mb-24">excluir conta</h2>
        <h3 className="color-text-default mb-8">digite sua senha</h3>
        <span className="color-text-default">
          Complete a exclusão da sua Conta SBT digitando sua senha
        </span>

        <div className="flex flex-row width-full mt-21 mb-26">
          <FormField
            name="password"
            label="senha"
            type="password"
            maxLength={PASS_MAX_LENGTH}
            customClass="width-full"
            placeholder="digite sua senha"
            register={register('password')}
            onInput={passwordMask}
            error={errors}
            touchedFields={touchedFields}
            sufix
            isPassword
          />
        </div>

        <div className="disable-account__actions mb-24">
          <Button
            id="disable-account"
            customColors="btn-red"
            className="mbi-0"
            disabled={
              loading ||
              !valueField.length ||
              valueField.length < 6 ||
              !!errors.password
            }
            onClick={confirmPassword}
          >
            <ButtonLabel loading={loading}>excluir conta</ButtonLabel>
          </Button>
          <Button
            customColors="btn-white"
            className="mt-16"
            onClick={backButton}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
    );
  }

  function backButton() {
    setCurrentStep(0);
    setValueField('');
    setValue('password', '');
  }

  function componentDisabledAccount() {
    return (
      <div className="width-full">
        <div className="icon-face-sad">
          <h2 className="color-text-default mb-8">
            sua Conta SBT foi excluída
            <Icon name="faceSad" />
          </h2>
        </div>
        <span className="flex mt-16 md color-text-default">
          Que pena não estarmos mais conectados!
        </span>
        <br />
        <span className="md strong color-text-default">
          {' '}
          Ainda dá tempo de ficar junto da gente.
        </span>
        <br />
        <span className="flex mt-16 mb-32 md color-text-default">
          Você tem 30 dias para mudar de ideia. Basta fazer login com seu e-mail
          e senha para reativar sua conta.
        </span>

        <div className="disable-account__actions mb-24">
          <Button onClick={redirectToLogin}>
            <ButtonLabel>sair</ButtonLabel>
          </Button>
        </div>
      </div>
    );
  }

  async function confirmPassword() {
    setLoading(true);
    try {
      const response = await userConfirmPassword(
        user.email,
        valueField,
        SentryContextsEnum.DELETE_ACCOUNT
      );
      if (response.passwordConfirmed) {
        disableAccount();
      } else {
        setError('password', {
          message: 'senha incorreta'
        });
        setLoading(false);
      }
    } catch (error) {
      showErrorMessageServer();
      setLoading(false);
    }
  }

  async function disableAccount() {
    setLoading(true);
    try {
      await updateAccountInformation(
        user.email,
        {
          activateAccount: false
        },
        ErrorMessages.DELETE_ACCOUNT
      );

      if (isWebview()) onAccountDisabled();

      setAccountDisabled(true);
      closeModal();
      setCurrentStep(2);
      setValueField('');
      setValue('password', '');
      setAccountHasBeenDisabled(true);
    } catch (error) {
      showErrorMessageServer();
      setLoading(false);
    }
  }

  const components = [
    { id: 'InfosDisabledAccount', component: componentInfosDisableAccount() },
    {
      id: 'PasswordDisableAccount',
      component: componentPasswordDisableAccount()
    },
    {
      id: 'DisabledAccount',
      component: componentDisabledAccount()
    }
  ];

  // Loggout event to WebView - Android
  function onExitWebview() {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const YoutubePlayerView = window.YoutubePlayerView;
      YoutubePlayerView.onExitWebview();
    } catch (error) {
      console.log(error);
    }
  }

  function onAccountDisabled() {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const YoutubePlayerView = window.YoutubePlayerView;
      YoutubePlayerView.onAccountDisabled();
    } catch (error) {
      console.log(error);
    }
  }

  async function redirectToLogin() {
    try {
      await logoutSSO();

      setOpenSession(false);
      clearSession();
      if (isWebview()) onExitWebview();
      else navigate('/', { replace: true });
    } catch (error) {
      console.error(error);
      if (isWebview()) onExitWebview();
      else showErrorMessageServer();
    }
  }

  const modalRef = useRef<ModalHandles>(null);
  function openModal() {
    modalRef.current?.openModal();
  }

  function closeModal() {
    modalRef.current?.closeModal();
  }

  function mountSteps() {
    return components.map((item, index) => {
      const component = item.component;
      return (
        <Step id={index} currentStep={currentStep} key={index}>
          {component}
        </Step>
      );
    });
  }

  return (
    <Card height="100%" paddingHorizontal="24" paddingVertical="24">
      <Wizard className="width-full">{mountSteps()}</Wizard>
      <Modal ref={modalRef}>
        <ModalIcon color="icon-red" icon="triangleExclamation" />
        <ModalTitle>tem certeza que quer excluir sua Conta SBT?</ModalTitle>
        <ModalDescription>
          Caso mude de ideia, você tem 30 dias contados a partir da exclusão
          para reativar sua conta. Depois disso, a conta será excluída para
          sempre.
        </ModalDescription>
        <div className="modal-actions">
          <Button
            id="social-disable-account"
            full
            onClick={disableAccount}
            className="mbi-0"
            customColors="btn-red"
            disabled={loading}
          >
            <ButtonLabel loading={loading}>excluir conta</ButtonLabel>
          </Button>

          <Button
            full
            customColors="btn-white"
            className="mbi-0"
            onClick={closeModal}
          >
            <ButtonLabel>cancelar</ButtonLabel>
          </Button>
        </div>
      </Modal>
    </Card>
  );
}
