import './SocialMedia.scss';
import ButtonGoogle from './ButtonGoogle';
import ButtonFace from './ButtonFace';
import ButtonApple from './ButtonApple';
import { SocialActionEnum } from '../../hooks/ControllerSocialMedia';

interface SocialMediaProps {
  context?: any;
  label: string;
  action?: SocialActionEnum;
}

export default function SocialMedia({
  context,
  label,
  action
}: SocialMediaProps) {
  return (
    <div className="box-social">
      <div className="social-medias">
        <ButtonFace action={action} />
        <ButtonApple action={action} />
        <ButtonGoogle action={action} />
      </div>

      <div className="box-social__text">
        <hr />
        <span className="md">{label}</span>
        <hr />
      </div>
    </div>
  );
}
