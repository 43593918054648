import './AlertBox.scss';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/triangle-exclamation.svg';
import React, {
  useState,
  useCallback,
  useImperativeHandle,
  ReactNode
} from 'react';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { isWebview } from '../../utils/GenericFunctions';

interface AlertBoxProps {
  error?: boolean;
  neutral?: boolean;
  text: string | ReactNode;
}

export interface AlertBoxHandles {
  openAlert: () => void;
}

const AlertBox: React.ForwardRefRenderFunction<
  AlertBoxHandles,
  AlertBoxProps
> = ({ error, neutral, text }, ref) => {
  const { setAlertBox } = useControllerFlowStepsContext();
  const [open, setOpen] = useState(false);

  function timeOutClose() {
    let initSeconds = 10;
    const interval = setInterval(() => {
      if (initSeconds == 0) {
        clearInterval(interval);
        closeAlert();
        initSeconds = 10;
      }
      --initSeconds;
    }, 1000);
  }

  const openAlert = useCallback(() => {
    timeOutClose();
    setOpen(true);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openAlert
    };
  });

  function closeAlert() {
    setAlertBox({ message: '', error: false, neutral: false });
    setOpen(false);
  }

  return (
    <div
      style={{ display: open ? 'flex' : 'none' }}
      data-testid="alert-box"
      className={
        'alert-box ' +
        (neutral
          ? 'alert-box-neutral'
          : error
          ? 'alert-box-error'
          : 'alert-box-success') +
        (isWebview() ? ' alert-box-webview' : '')
      }
    >
      <div className="alert-box__icon-left">
        {neutral ? <WarningIcon /> : error ? <ErrorIcon /> : <SuccessIcon />}
      </div>
      <div className="alert-box__text">
        <span>{text}</span>
      </div>
      <div className="alert-box__icon-right">
        <CloseIcon onClick={closeAlert} />
      </div>
    </div>
  );
};

export default React.forwardRef(AlertBox);

// Ex: implementation
// Import 'AlertBoxHandles' and 'openAlert' from this file and use 'useRef' ex: const alertBoxRef = useRef<AlertBoxHandles>(null);
// 'openAlert' function open AlertBox
{
  /* <AlertBox
      ref={alertBoxRef}
      text="this is placeholder text for an alert banner."/>
  */
}
