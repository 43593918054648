import { KEY_REFRESH_SESSION, KEY_SESSION } from '../hooks/AuthContext';
import { GET, POST } from './api';
import { getCookie } from './cookieController';

const ACCOUNT_SERVICE = process.env.REACT_APP_BASE_URL_ACCOUNT_SERVICE;

export async function validateCTVCode(code: string): Promise<void> {
  const response = await POST(`${ACCOUNT_SERVICE}/api/v1/ctv/code/check`, {
    code
  });
  return response.data;
}

interface ICTVRecieveResponse {
  used: boolean;
}

export async function saveCTVCredentials(
  code: string
): Promise<ICTVRecieveResponse> {
  const token = getCookie(KEY_SESSION);
  const refreshToken = getCookie(KEY_REFRESH_SESSION);

  const response = await POST(`${ACCOUNT_SERVICE}/api/v1/ctv/code/${code}`, {
    token,
    refreshToken
  });
  return response?.data;
}

export async function checkCTVRecieveResponse(
  code: string
): Promise<ICTVRecieveResponse> {
  const response = await GET(`${ACCOUNT_SERVICE}/api/v1/ctv/code/used/${code}`);
  return response.data;
}
