import { useState, useEffect } from 'react';
import LogoText from '../../../components/LogoText/LogoText';
import FormField from '../../../components/FormField/FormField';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import Button from '../../../components/Button/Button';
import { createTokenRecoveryPassword } from '../../../services/tokenValidation';
import { useNavigate } from 'react-router-dom';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import { useAccountRecoveryContext } from '../../../data/AccountRecovery/AccountRecoveryContext';
import { checkEmailExist } from '../../../services/queryController';
import { ErrorMessages } from '../../../data/ErrorMessages';

export interface PasswordRecoveryEmailProps {
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

export default function PasswordRecoveryEmail({
  nextPath,
  handleChangeStep
}: PasswordRecoveryEmailProps) {
  const [fieldValidate, setValueValidateField] = useState('');
  const {
    register,
    watch,
    errors,
    setError,
    getValues,
    setValue,
    touchedFields
  } = useAccountRecoveryContext();

  const [loading, setLoading] = useState(false);
  const { showErrorMessageServer } = useControllerFlowStepsContext();

  useEffect(() => {
    const email: any = getValues('email');
    setValue(email, { shouldTouch: true });
    setValueValidateField(email);
  }, []);

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateField(data.email);
    });
    return () => subscription?.unsubscribe();
  }, [watch]);

  const { getOrigin } = useControllerFlowStepsContext();

  const url = `${
    !getOrigin()
      ? '/recuperar-senha/email'
      : '/' + getOrigin() + '/recuperar-senha/email'
  }`;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url);
  }, [navigate]);

  function nextStep() {
    handleChangeStep(nextPath ?? 1);
  }

  function backPage() {
    navigate(
      getOrigin() == 'videos' ? '/videos' : getOrigin() === 'sbt' ? '/sbt' : '/'
    );
  }

  window.onpopstate = () => {
    backPage();
  };

  async function callCreateToken() {
    const formatValue = fieldValidate;
    setLoading(true);

    try {
      await createTokenRecoveryPassword(formatValue);
      setLoading(false);
      nextStep();
    } catch (error: any) {
      setLoading(false);
      setError('email', {
        message:
          error?.statusCode === 429
            ? 'Você atingiu o limite de tentativas. Por favor, volte mais tarde.'
            : 'Não foi possível prosseguir com a alteração de senha para esse e-mail'
      });
      console.error(error);
    }
  }

  async function emailExist() {
    const email = getValues('email');
    if (email && !errors.email) {
      try {
        const response = await checkEmailExist(
          email,
          ErrorMessages.EMAIL_EXIST_FORGOT_PASSWORD
        );
        if (!response?.exist) {
          setError('email', {
            message: 'e-mail não encontrado no sistema'
          });
          setLoading(false);
        } else {
          callCreateToken();
        }
      } catch (_) {
        showErrorMessageServer();
        setLoading(false);
      }
    }
  }

  return (
    <div className="flex flex-column">
      <LogoText />
      <h2>esqueceu a senha?</h2>
      <span className="md mt-4">
        Informe o e-mail cadastrado para recuperar sua senha
      </span>

      <div className="flex flex-column mt-16">
        <FormField
          name="email"
          label="e-mail"
          type="email"
          placeholder="digite seu e-mail"
          register={register('email')}
          error={errors}
          touchedFields={touchedFields}
        />
        <Button
          id="password-recovery"
          className="mt-8 mbi-0"
          full
          onClick={emailExist}
          disabled={
            loading ||
            !!errors.email ||
            (fieldValidate?.length && fieldValidate?.length < 13) ||
            !fieldValidate?.length
          }
        >
          <ButtonLabel loading={loading}>avançar</ButtonLabel>
        </Button>

        <Button
          className="mt-16"
          customColors="btn-white"
          full
          onClick={backPage}
        >
          <ButtonLabel>voltar</ButtonLabel>
        </Button>
      </div>
    </div>
  );
}
