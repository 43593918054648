import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import FormField from '../../../components/FormField/FormField';
import LogoText from '../../../components/LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import { checkExistNumberPhone } from '../../../services/queryController';
import { createTokenValidatePhone } from '../../../services/tokenValidation';
import { mobilePhoneMask } from '../../../utils/Masks';
import { useAccountRecoveryContext } from '../../../data/AccountRecovery/AccountRecoveryContext';
import { LINK_CENTRAL_HELP } from '../../../data/Constants';
import { SentryContextsEnum } from '../../../utils/Sentry';

export interface EmailRecoveryProps {
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

export default function EmailRecovery({
  nextPath,
  handleChangeStep
}: EmailRecoveryProps) {
  const { getOrigin, showErrorMessageServer, showCooldownErrorMessage } =
    useControllerFlowStepsContext();
  const { register, errors, watch, touchedFields, getValues, setError } =
    useAccountRecoveryContext();
  const [fieldValidate, setValueValidateField] = useState('');
  const [loading, setLoading] = useState(false);
  const url = `${
    !getOrigin()
      ? '/recuperar-conta/email'
      : '/' + getOrigin() + '/recuperar-conta/email'
  }`;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url);
  }, [navigate]);

  window.onpopstate = () => {
    backPage();
  };

  function backPage() {
    navigate(
      getOrigin() == 'videos' ? '/videos' : getOrigin() === 'sbt' ? '/sbt' : '/'
    );
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateField(data.cellPhoneNumber);
    });
    return () => subscription?.unsubscribe();
  }, [watch]);

  function changeToViewToken() {
    checkExistPhone();
  }

  async function checkExistPhone() {
    let numberPhone = getValues('cellPhoneNumber');
    numberPhone = numberPhone?.replace(/\D/g, '')?.replace(/\s/g, '');
    setLoading(true);
    try {
      const response = await checkExistNumberPhone(
        numberPhone,
        SentryContextsEnum.FORGOT_ACCOUNT
      );
      if (!response?.exist) {
        setError('cellPhoneNumber', {
          message:
            'número não encontrado no sistema. Entre em contato com o suporte'
        });
        setLoading(false);
      } else {
        postCreateToken();
      }
    } catch (error: any) {
      if (error?.statusCode === 429) showCooldownErrorMessage();
      else showErrorMessageServer();
      setLoading(false);
    }
  }

  async function postCreateToken() {
    try {
      const email = getValues('cellPhoneNumber');
      const formatValue = email?.replace(/\D/g, '');
      setLoading(true);
      await createTokenValidatePhone(
        formatValue,
        SentryContextsEnum.FORGOT_ACCOUNT
      );
      nextStep();
    } catch (error: any) {
      if (error?.statusCode === 429) showCooldownErrorMessage();
      else showErrorMessageServer();
      setLoading(false);
    }
  }

  function nextStep() {
    if (nextPath) {
      handleChangeStep(nextPath);
    }
  }

  function redirectCentralHelp() {
    window.open(LINK_CENTRAL_HELP, '_blank');
  }

  return (
    <>
      <div className="flex flex-column">
        <LogoText />
        <h2 className="color-text-default">esqueceu seu e-mail?</h2>
        <span className="md mt-4 color-text-default">
          Informe o número de celular cadastrado para
          <br />
          recuperar sua conta
        </span>

        <div className="flex flex-column mt-16">
          <FormField
            label="número de celular"
            prefix
            maxLength="9"
            labelPrefix="+55"
            iconPrefix="flagBrasil"
            placeholder="digite seu celular"
            inputMode="tel"
            name="cellPhoneNumber"
            onKeyUp={mobilePhoneMask}
            register={register('cellPhoneNumber')}
            error={errors}
            touchedFields={touchedFields}
          />

          <Button
            id="account-recovery-email"
            className="mt-24 mbi-0"
            full
            onClick={changeToViewToken}
            disabled={!!errors.email || !fieldValidate.length || loading}
          >
            <ButtonLabel loading={loading}>avançar</ButtonLabel>
          </Button>
          <Button
            className="mt-16 mbi-0"
            customColors="btn-white"
            full
            onClick={backPage}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
        <div className="text-center mt-16">
          <span className="color-text-default">
            se precisar de nós, acesse nossa
            <br />
            <span
              onClick={redirectCentralHelp}
              className="strong link-intern-text ml-3 text-purple"
            >
              central de ajuda
            </span>
          </span>
        </div>
      </div>
    </>
  );
}
