import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertBox, { AlertBoxHandles } from '../../components/AlertBox/AlertBox';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import LogoText from '../../components/LogoText/LogoText';
import { useAuthContext } from '../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { createTokenValidateEmail } from '../../services/tokenValidation';
import CheckToken from '../CheckToken';
import ChangeEmail from './ChangeEmail';
export interface ValidateEmailProps {
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
  viewSuccess?: any;
}

export default function ValidateEmail({
  allowSkipStep,
  nextPath,
  previousPath,
  handleChangeStep,
  context
}: ValidateEmailProps) {
  const { redirectToSite } = useAuthContext();
  const { originDomainForm, getValues } = useContext<any>(context);
  const {
    socialData,
    showErrorMessageServer,
    showCooldownErrorMessage,
    setValidEmail,
    setNotCreateAccount,
    ctvCode
  } = useControllerFlowStepsContext();
  const [viewValidateEmail, setViewValidateEmail] = useState(true);
  const [viewChangeEmail, setViewChangeEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = `/${originDomainForm}/validar-email`;
  const navigate = useNavigate();

  useEffect(() => {
    setValidEmail(false);
    setNotCreateAccount(true);
  }, []);

  useEffect(() => {
    navigate(url, { replace: true });
  }, [navigate]);

  async function callCreateToken(resend?: boolean) {
    const email = socialData.email || getValues('email');
    if (email) {
      setLoading(true);
      try {
        await createTokenValidateEmail(email);
        if (resend) openAlert();
      } catch (error: any) {
        if (error?.statusCode === 429) showCooldownErrorMessage();
        else showErrorMessageServer();
      } finally {
        setLoading(false);
      }
    } else {
      showErrorMessageServer();
    }
  }

  const alertBoxRef = useRef<AlertBoxHandles>(null);
  function openAlert() {
    alertBoxRef.current?.openAlert();
  }

  async function changeToViewToken() {
    setValidEmail(true);
    await callCreateToken();
    setViewValidateEmail(false);
  }

  function previousStep() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    }
  }

  function handleChangeEmail() {
    setViewChangeEmail(true);
  }

  return (
    <>
      {viewValidateEmail ? (
        viewChangeEmail ? (
          <ChangeEmail
            context={context}
            setViewChangeEmail={setViewChangeEmail}
          />
        ) : (
          <div className="flex flex-column">
            <LogoText />
            <h2>valide seu e-mail</h2>
            <span className="md mt-4">
              Para concluir o acesso à sua conta precisamos validar seu endereço
              de e-mail.
            </span>
            <span className="md mt-16">
              Clique no botão “enviar código” para receber o código de validação
              no e-mail{' '}
              <span className="strong">
                {socialData.email || getValues('email')}
              </span>
            </span>
            <span className="link ml-6" onClick={handleChangeEmail}>
              alterar e-mail
            </span>

            <div className="flex flex-column mt-16">
              <Button
                className="mt-8 mbi-0"
                full
                onClick={changeToViewToken}
                loading={loading}
                disabled={loading}
              >
                <ButtonLabel loading={loading}>enviar código</ButtonLabel>
              </Button>
              <Button
                className="mt-16"
                customColors="btn-white"
                full
                onClick={previousStep}
              >
                <ButtonLabel>voltar</ButtonLabel>
              </Button>
            </div>
          </div>
        )
      ) : (
        <CheckToken
          context={context}
          goBackView={setViewValidateEmail}
          callCreateToken={callCreateToken}
          previousPath={0}
          nextPath={nextPath}
          handleChangeStep={handleChangeStep}
          isEmail
        />
      )}
      <AlertBox ref={alertBoxRef} text="código enviado com sucesso" />
    </>
  );
}
