import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Container from '../../../components/Container/Container';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import './UpdateUserData.scss';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useUpdateUserDataContext } from '../../../data/UpdateUserData/UpdateUserDataContext';
import { CODE_INVALID_CPF, GENRE } from '../../../data/Constants';
import {
  checkExistsCpf,
  sessionUserInfo
} from '../../../services/queryController';
import { useAuthContext } from '../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import {
  checkSocialName,
  clearSpecialCharactersFromNumber,
  compareForm,
  formatCep,
  formatCpf,
  formatDateToDDMMYYYY,
  formatDateToSend,
  formatPhoneWithPrefix,
  isWebview
} from '../../../utils/GenericFunctions';
import Modal from '../../../components/Modal/Modal';
import ModalTitle from '../../../components/Modal/ModalTitle';
import ModalDescription from '../../../components/Modal/ModalDescription';
import ModalIcon from '../../../components/Modal/ModalIcon';
import {
  updateTelephone,
  checkUserHasPassword,
  updateUserInfos,
  updateAddress
} from '../../../services/user';
import { useControlNavBarContext } from '../../../hooks/ControlNavBar';
import Icon from '../../../components/Icon/Icon';
import { IUser } from '../../../interfaces/user';

let copyForm: any = {};
let initView = false;

export default function UpdateUserData() {
  const { user, setUser, checkAuth } = useAuthContext();
  const {
    loadingFullScreen,
    setLoadingFullScreen,
    setAlertBox,
    showErrorMessageServer
  } = useControllerFlowStepsContext();
  const {
    errors,
    setError,
    getValues,
    setValue,
    watch,
    setUpdatePhone,
    setUpdatedData,
    setVisibleFieldGenrer,
    setHasCpf,
    setNoNumber,
    setUserHasPassowrd,
    modalRef,
    sizeView,
    selectedLink,
    setSelectedLink,
    isFormEqual,
    setFormEqual,
    showModalPendingDataToSave,
    setshowModalPendingDataToSave,
    openModalPendingDataToSave
  } = useUpdateUserDataContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const navigate = useNavigate();
  const [loadingDeletePhone, setLoadingDeletePhone] = useState(false);
  const [hiddenPanel, setHiddenPanel] = useState(false);
  const [previousSizeView, setPreviousSizeView] = useState(sizeView);
  const links = [
    {
      title: 'dados da conta',
      id: 'accountData',
      path: 'conta',
      selected: false
    },
    {
      title: 'dados pessoais',
      id: 'personalData',
      path: 'dados-pessoais',
      selected: false
    },
    {
      title: 'endereço',
      id: 'addressData',
      path: 'endereco',
      selected: false
    }
  ];

  const location = useLocation();

  function configGoBack() {
    setGoBackNavigate({
      path: '',
      param: {}
    });
  }

  useEffect(() => {
    getUserInfos();
    setUpdatePhone(false);
    setShowButton(true);
    configGoBack();
    controlViewMobile();
  }, []);

  useEffect(() => {
    if (location.pathname === '/menu-de-conta/atualizar-dados/conta') {
      selectLinkMenu(links[0], 0);
    }
  }, [hiddenPanel]);

  useEffect(() => {
    if (
      (!isWebview() || previousSizeView?.[0] === 0) &&
      sizeView?.[0] !== previousSizeView?.[0]
    )
      controlViewMobile();
    setPreviousSizeView(sizeView);
  }, [sizeView]);

  useEffect(() => {
    if (location?.state == 'refresh') {
      setSelectedLink(-1);
      setTimeout(() => {
        configGoBack();
      }, 500);
    }
  }, [location]);

  function controlViewMobile() {
    setHiddenPanel(sizeView[0] <= 576);
    setSelectedLink(sizeView[0] <= 576 ? -1 : 0);
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      if (initView) {
        comparingForms(data);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  window.onpopstate = () => {
    controlBackPage();
  };

  function controlBackPage() {
    if (isFormEqual.equal) {
      redirectMenu();
    } else {
      setshowModalPendingDataToSave(true);
      openModalPendingDataToSave();
      if (hiddenPanel && selectedLink > -1) {
        navigate(`/menu-de-conta/atualizar-dados/${links[selectedLink].path}`);
      } else {
        navigate('/menu-de-conta/atualizar-dados');
      }
    }
  }

  async function getUserHasPassword(email: string) {
    const response = await checkUserHasPassword(email);
    setUserHasPassowrd(response.hasPassword);
  }

  async function getUserInfos() {
    setLoadingFullScreen(true);
    try {
      const { email } = checkAuth().user;
      const userData = await sessionUserInfo();
      setUser(userData);
      setUserInfos(userData);
      setUserAddress(userData);
      getUserHasPassword(email);
      setUpdatedData(false);
      copyFormData();
    } catch (_) {
      showErrorMessageServer();
    } finally {
      setLoadingFullScreen(false);
      setTimeout(() => {
        initView = true;
      }, 1000);
    }
  }

  function setUserInfos(userData: IUser, fieldsFormatted?: boolean) {
    setValue('userName', userData.userName || '');
    setValue(
      'cpf',
      fieldsFormatted ? userData.cpf : formatCpf(userData.cpf) || ''
    );
    setValue('socialName', userData.socialName || '');
    setValue('hasSocialName', userData.hasSocialName);
    setValue(
      'cellPhoneNumber',
      formatPhoneWithPrefix(userData.cellPhoneNumber) || ''
    );
    setValue(
      'birthDate',
      fieldsFormatted
        ? userData.birthDate
        : formatDateToDDMMYYYY(userData.birthDate) || ''
    );
    setValue('gender', userData.gender);
    setValue('kindGender', userData.kindGender || '');
    setVisibleFieldGenrer(userData.gender?.toLocaleUpperCase() === GENRE.OTHER);
    setHasCpf(!!userData.cpf);
  }

  function setUserAddress(userData: IUser) {
    setValue('cep', formatCep(userData.cep) || '');
    setValue('complement', userData.complement || '');
    setValue('hasAddressNumber', userData.hasAddressNumber);
    setValue('state', userData.state || '');
    setValue('city', userData.city || '');
    setValue('streetName', userData.streetName || '');
    setValue('neighborhood', userData.neighborhood || '');
    setValue('addressNumber', userData.addressNumber || '');
    setNoNumber(!userData.hasAddressNumber);
  }

  function mountLinksMenu() {
    return links.map((link, index) => {
      return (
        <div
          key={index}
          onClick={() => selectLinkMenu(link, index)}
          className={`udpate-user-data-menu__link ${
            index == selectedLink ? 'link-selected' : ''
          }`}
        >
          {link.title}
          {hiddenPanel ? <Icon name="arrowRight" /> : null}
        </div>
      );
    });
  }

  function selectLinkMenu(link: any, index: number) {
    if (!hiddenPanel) {
      const element: any = document.querySelector(`#${link.id}`);
      const container: any = document.querySelector('.container');
      const positionY = element.offsetTop;

      container.scrollTo({
        top: index == 0 ? 0 : positionY - 72,
        behavior: 'smooth'
      });
    } else {
      navigate(link.path);
    }

    setSelectedLink(index);
  }

  async function checkNotExistsAccountWithCpf(cpf: string) {
    setLoadingFullScreen(true);
    try {
      const response = await checkExistsCpf(cpf.replace(/\D/g, ''));
      if (response.exist) {
        setError('cpf', {
          message: 'CPF já vinculado a uma conta'
        });
        return false;
      }
      return true;
    } catch (error) {
      showErrorMessageServer();
      return false;
    } finally {
      setLoadingFullScreen(false);
    }
  }

  function closeModal() {
    setshowModalPendingDataToSave(false);
    modalRef.current?.closeModal();
  }

  async function deletePhone() {
    setLoadingDeletePhone(true);
    try {
      await updateTelephone(user.email, '');
      setAlertBox({
        message: 'seu número de celular foi removido',
        error: false
      });
      closeModal();
      getUserInfos();
    } catch (error) {
      setLoadingDeletePhone(false);
      console.error(error);
      setAlertBox({
        message: 'seu número de celular não foi removido',
        error: true
      });
    }
  }

  function mountModalDeletePhone() {
    return (
      <>
        <ModalIcon icon="triangleExclamation" />
        <ModalTitle>remover número de celular</ModalTitle>
        <ModalDescription>
          Caso você esqueça ou perca seu e-mail, você precisará
          <br /> entrar em contato com o suporte para recuperar sua conta.
        </ModalDescription>
        <div className="modal-actions">
          <Button
            id="confirm-delete-phone"
            className="mbi-0"
            full
            onClick={deletePhone}
            loading={loadingDeletePhone}
          >
            <ButtonLabel loading={loadingDeletePhone}>remover</ButtonLabel>
          </Button>

          <Button
            className="mbi-0"
            full
            customColors="btn-white"
            onClick={closeModal}
            loading={loadingDeletePhone}
          >
            <ButtonLabel>cancelar</ButtonLabel>
          </Button>
        </div>
      </>
    );
  }

  function mountModalPendingDataToUpdate() {
    return (
      <>
        <ModalIcon icon="triangleExclamation" />
        <ModalTitle>Alterações não salvas</ModalTitle>
        <ModalDescription>
          Parece que você tem alterações que não foram salvas, você
          <br className="breakline-desktop" /> tem certeza que{' '}
          <br className="breakline-mobile" /> deseja descartá-las?
        </ModalDescription>
        <div className="modal-actions">
          <Button
            className="mbi-0"
            full
            onClick={closeModalPendingDataToUpdate}
          >
            <ButtonLabel>descartar alterações</ButtonLabel>
          </Button>

          <Button
            className="mbi-0"
            full
            customColors="btn-white"
            onClick={closeModal}
          >
            <ButtonLabel>cancelar</ButtonLabel>
          </Button>
        </div>
      </>
    );
  }

  async function comparingForms(formChange: any) {
    const formEqual = await compareForm(copyForm, formChange);

    setFormEqual(formEqual);
  }

  function formatValuesToSave() {
    const fields: any = {};
    isFormEqual.fieldsNotEqual.map((item: any) => {
      switch (item.field) {
        case 'cep': {
          fields[item.field] =
            clearSpecialCharactersFromNumber(item.newValue) || '';
          break;
        }
        case 'birthDate': {
          fields[item.field] = formatDateToSend(item.newValue) || '';
          break;
        }
        case 'cpf': {
          fields[item.field] =
            clearSpecialCharactersFromNumber(item.newValue) || '';
          break;
        }
        default: {
          fields[item.field] = item.newValue;
          break;
        }
      }
    });
    return fields;
  }

  async function sendUserInfosToUpdate() {
    const valuesToSave = formatValuesToSave();
    const fieldsUser = [
      'userName',
      'cpf',
      'socialName',
      'hasSocialName',
      'birthDate',
      'gender',
      'kindGender'
    ];
    const userFieldsToSave: any = {};
    const addressFieldsToSave: any = {
      cep: clearSpecialCharactersFromNumber(getValues('cep') || ''),
      state: getValues('state') || '',
      city: getValues('city') || '',
      neighborhood: getValues('neighborhood') || '',
      streetName: getValues('streetName') || '',
      addressNumber: getValues('addressNumber') || '',
      hasAddressNumber: getValues('hasAddressNumber'),
      complement: getValues('complement') || ''
    };
    const addressFields = [
      'cep',
      'state',
      'city',
      'neighborhood',
      'streetName',
      'addressNumber',
      'hasAddressNumber',
      'complement'
    ];
    let isUpdateAddress = false;
    for (const [key, value] of Object.entries(valuesToSave)) {
      const hasField = addressFields.find((field: any) => field === key);
      if (hasField) {
        addressFieldsToSave[key] = value;
        isUpdateAddress = true;
      }
    }

    for (const [key, value] of Object.entries(valuesToSave)) {
      const hasField = fieldsUser.find((field: any) => field === key);
      if (hasField) userFieldsToSave[key] = value;
    }

    let notExistCpf = true;
    if (userFieldsToSave['cpf']) {
      notExistCpf = await checkNotExistsAccountWithCpf(userFieldsToSave['cpf']);
    }

    if (!isFormEqual.equal && notExistCpf) {
      setLoadingFullScreen(true);
      try {
        if (Object.keys(userFieldsToSave).length) {
          if (userFieldsToSave.kindGender === '')
            userFieldsToSave.kindGender = null;
          await updateUserInfos(user.email, userFieldsToSave);
        }
        if (isUpdateAddress) {
          await updateAddress(user.email, addressFieldsToSave);
        }
        setAlertBox({
          message: 'Suas alterações foram salvas',
          error: false
        });
        copyFormData();
        setFormEqual({
          equal: true,
          fieldsNotEqual: []
        });
        if (userFieldsToSave['cpf']) {
          setHasCpf(true);
        }
      } catch (error: any) {
        console.error(error);
        if (error?.code === CODE_INVALID_CPF)
          setAlertBox({
            message: <span>{error?.message}</span>,
            error: true
          });
        else showErrorMessageServer();
      } finally {
        setLoadingFullScreen(false);
      }
    }
  }

  function copyFormData() {
    copyForm = JSON.parse(
      JSON.stringify({
        userName: getValues('userName') || '',
        socialName: getValues('socialName') || '',
        hasSocialName: getValues('hasSocialName'),
        birthDate: getValues('birthDate'),
        gender: getValues('gender'),
        kindGender: getValues('kindGender') || '',
        cpf: getValues('cpf') || '',
        cep: getValues('cep') || '',
        state: getValues('state') || '',
        city: getValues('city') || '',
        neighborhood: getValues('neighborhood') || '',
        streetName: getValues('streetName') || '',
        addressNumber: getValues('addressNumber') || '',
        hasAddressNumber: getValues('hasAddressNumber'),
        complement: getValues('complement') || ''
      })
    );
  }

  async function closeModalPendingDataToUpdate() {
    setAlertBox({
      message: 'Suas alterações foram descartadas',
      error: true
    });

    setFormEqual({
      equal: true,
      fieldsNotEqual: []
    });
    setUserInfos(copyForm, true);
    setUserAddress(copyForm);
    setUpdatedData(false);
    closeModal();

    redirectMenu();
  }

  function redirectMenu() {
    if (hiddenPanel && selectedLink > -1) {
      navigate('/menu-de-conta/atualizar-dados', {
        state: 'refresh'
      });
      configGoBack();
    } else {
      navigate('/menu-de-conta', { replace: true });
    }
  }

  function mountButtonUpdateData() {
    const fieldHasSocialName = getValues('hasSocialName');
    const fieldSocialName = getValues('socialName');

    const gender = getValues('gender');
    const kindGender = getValues('kindGender');

    return (
      <Button
        id="update-user-data"
        loading={loadingFullScreen}
        disabled={
          isFormEqual.equal ||
          !!errors.cpf ||
          !!errors.birthDate ||
          !!errors.userName ||
          !!errors.cep ||
          (getValues('cep') &&
            (!!errors.city || !getValues('city') || !getValues('state'))) ||
          !!errors.addressNumber ||
          !getValues('userName') ||
          (fieldHasSocialName && checkSocialName(fieldSocialName)) ||
          (gender === GENRE.OTHER && kindGender.length < 2)
        }
        className="mt-24 mbi-0"
        onClick={sendUserInfosToUpdate}
      >
        <ButtonLabel>
          {hiddenPanel ? 'salvar' : 'salvar alterações'}
        </ButtonLabel>
      </Button>
    );
  }

  return (
    <Container>
      <div className={`menu-account ${hiddenPanel ? 'height-full' : ''}`}>
        <div
          className={`menu-account__left ${
            hiddenPanel && selectedLink > -1 ? 'hidden-panel' : ''
          }`}
        >
          <Card paddingHorizontal="0" paddingVertical="0" sticky>
            <div className={'udpate-user-data-menu'}>
              {mountLinksMenu()}
              <div className="pl-32 pr-32 width-full flex flex-column border-box">
                {!hiddenPanel ? mountButtonUpdateData() : null}
                <Button
                  loading={loadingFullScreen}
                  onClick={controlBackPage}
                  className="mt-16 mb-24"
                  customColors="btn-white"
                >
                  <ButtonLabel>voltar</ButtonLabel>
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div
          className={`menu-account__right ${
            hiddenPanel && selectedLink > 0
              ? 'menu-account__right-btn-spacing'
              : ''
          }`}
        >
          {hiddenPanel ? selectedLink > -1 ? <Outlet /> : null : <Outlet />}
        </div>
        {hiddenPanel && selectedLink > 0 ? (
          <div className="btn-update-data-mobile">
            <div>{mountButtonUpdateData()}</div>
          </div>
        ) : null}
      </div>
      <Modal ref={modalRef}>
        {showModalPendingDataToSave
          ? mountModalPendingDataToUpdate()
          : mountModalDeletePhone()}
      </Modal>
    </Container>
  );
}
