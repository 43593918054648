import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import './ManageCommunicationPreferences.scss';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import Card from '../../../components/Card/Card';
import Container from '../../../components/Container/Container';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/Modal/Modal';
import ModalDescription from '../../../components/Modal/ModalDescription';
import ModalIcon from '../../../components/Modal/ModalIcon';
import ModalTitle from '../../../components/Modal/ModalTitle';
import { useManagerPreferencesContext } from '../../../data/ManagerPreferences/ManagerPreferences';
import { useAuthContext } from '../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../hooks/ControlNavBar';
import {
  updatePreferencesAnnouncements,
  updateTypesCommunicate
} from '../../../services/user';

export default function ManageCommunicationPreferences() {
  const { checkAuth } = useAuthContext();
  const { loadingFullScreen, setLoadingFullScreen, setAlertBox } =
    useControllerFlowStepsContext();
  const [hiddenPanel, setHiddenPanel] = useState(false);
  const {
    selectedLink,
    setSelectedLink,
    checkTypeCommunicate,
    copyAnnouncements,
    checkTypeSubjectsAnnouncements,
    copyTypeCommunicate,
    copyTypesCommunicate,
    copySubjectsAnnouncements,
    sizeView,
    modalRef,
    setshowModalPendingDataToSave,
    openModalPendingDataToSave,
    setFormEqual,
    isFormEqual
  } = useManagerPreferencesContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const [equalData, setEqualData] = useState({
    isEqual: true,
    changeSubjectAnnouncements: false,
    changeTypeCommunicate: false
  });
  const navigate = useNavigate();
  const location = useLocation();

  const links = [
    {
      title: 'tipos de comunicados',
      id: 'typeCommunicate',
      path: 'tipos-de-comunicados',
      selected: false
    },
    {
      title: 'gerenciar assuntos dos comunicados',
      id: 'subjectCommunicate',
      path: 'gerenciar-assuntos-dos-comunicados',
      selected: false
    },
    {
      title: 'notificações',
      id: 'notification',
      path: 'notificacoes',
      selected: false
    }
  ];

  useEffect(() => {
    setShowButton(true);
    configGoBack();
  }, []);

  useEffect(() => {
    controlViewMobile();
  }, [sizeView]);

  function controlViewMobile() {
    setHiddenPanel(sizeView[0] <= 576);
    setSelectedLink(sizeView[0] <= 576 ? -1 : 0);
  }

  useEffect(() => {
    if (location?.state == 'refresh') {
      setSelectedLink(-1);
      setTimeout(() => {
        configGoBack();
      }, 500);
    }
  }, [location]);

  function configGoBack() {
    setGoBackNavigate({
      path: '/menu-de-conta',
      param: {}
    });
  }

  useEffect(() => {
    setFormEqual({ equal: equalData.isEqual });
  }, [equalData]);

  useEffect(() => {
    if (copyTypeCommunicate) {
      if (
        JSON.stringify(copyTypeCommunicate) ==
        JSON.stringify(checkTypeCommunicate)
      ) {
        setEqualData((state) => ({
          ...state,
          isEqual: !equalData.changeSubjectAnnouncements,
          changeTypeCommunicate: false
        }));
      } else {
        setEqualData((state) => ({
          ...state,
          isEqual: false,
          changeTypeCommunicate: true
        }));
      }
    }
  }, [checkTypeCommunicate]);

  useEffect(() => {
    if (copyAnnouncements) {
      if (
        JSON.stringify(copyAnnouncements) ==
        JSON.stringify(checkTypeSubjectsAnnouncements)
      ) {
        setEqualData((state) => ({
          ...state,
          isEqual: !equalData.changeTypeCommunicate,
          changeSubjectAnnouncements: false
        }));
      } else {
        setEqualData((state) => ({
          ...state,
          isEqual: false,
          changeSubjectAnnouncements: true
        }));
      }
    }
  }, [checkTypeSubjectsAnnouncements]);

  window.onpopstate = () => {
    redirectMenu();
  };

  function redirectMenu() {
    if (hiddenPanel && selectedLink > -1) {
      navigate('/menu-de-conta/preferencias-de-comunicacao', {
        state: 'refresh'
      });
      configGoBack();
    } else {
      navigate('/menu-de-conta', { replace: true });
    }
  }

  function controlBackPage() {
    if (isFormEqual.equal) {
      redirectMenu();
    } else {
      setshowModalPendingDataToSave(true);
      openModalPendingDataToSave();
      if (hiddenPanel && selectedLink > -1) {
        navigate(
          `/menu-de-conta/preferencias-de-comunicacao/${links[selectedLink].path}`
        );
      } else {
        navigate('/menu-de-conta/preferencias-de-comunicacao');
      }
    }
  }

  function mountLinksMenu() {
    return links.map((link, index) => {
      return (
        <div
          key={index}
          onClick={() => selectLinkMenu(link, index)}
          className={`udpate-user-data-menu__link ${
            index == selectedLink ? 'link-selected' : ''
          }`}
        >
          {link.title}
          {hiddenPanel ? <Icon name="arrowRight" /> : null}
        </div>
      );
    });
  }

  function selectLinkMenu(link: any, index: number) {
    if (!hiddenPanel) {
      const element: any = document.querySelector(`#${link.id}`);
      const container: any = document.querySelector('.container');
      const positionY = element.offsetTop;

      container.scrollTo({
        top: index == 0 ? 0 : positionY - 72,
        behavior: 'smooth'
      });
    } else {
      navigate(link.path);
    }

    setSelectedLink(index);
  }

  function mountButtonSave() {
    return (
      <Button
        id="update-communication-preferences"
        loading={loadingFullScreen}
        disabled={equalData.isEqual}
        onClick={updateData}
        className="mt-24 mbi-0"
      >
        <ButtonLabel>salvar alterações</ButtonLabel>
      </Button>
    );
  }

  function transformSubjectAnnouncementsToSave() {
    const keys = [
      'maisSbt',
      'sbtNews',
      'tvSchedule',
      'onlineContent',
      'sbtGames',
      'sbtSports',
      'sbtProducts',
      'promotions',
      'sbtPartners',
      'institutional',
      'researches'
    ];
    const objToSave: any = {};

    checkTypeSubjectsAnnouncements.map((item) => {
      keys.map((key) => {
        if (key === item.value) {
          objToSave[key] = item.active;
        }
      });
    });

    return objToSave;
  }

  async function updateData() {
    setLoadingFullScreen(true);
    const valuesSubject = transformSubjectAnnouncementsToSave();
    let save = false;
    try {
      if (equalData.changeSubjectAnnouncements) {
        await updatePreferencesAnnouncements(
          checkAuth()?.user?.email,
          valuesSubject
        );
        save = true;
      }
      if (equalData.changeTypeCommunicate) {
        await updateTypesCommunicate(
          checkAuth()?.user?.email,
          checkTypeCommunicate
        );
        save = true;
      }
      if (save) {
        setAlertBox({
          message: 'suas alterações foram salvas',
          error: false
        });
        setEqualData({
          isEqual: true,
          changeSubjectAnnouncements: false,
          changeTypeCommunicate: false
        });
        copyTypesCommunicate(checkTypeCommunicate);
        copySubjectsAnnouncements(checkTypeSubjectsAnnouncements);
      }
    } catch (error) {
      console.error(error);
      setAlertBox({
        message:
          'Não foi possível salvar as alterações. Tente novamente mais tarde. ',
        error: true
      });
    } finally {
      setLoadingFullScreen(false);
    }
  }

  function closeModal() {
    setshowModalPendingDataToSave(false);
    modalRef.current?.closeModal();
  }

  async function closeModalPendingDataToUpdate() {
    setAlertBox({
      message: 'Suas alterações foram descartadas',
      error: true
    });
    setEqualData({
      isEqual: true,
      changeSubjectAnnouncements: false,
      changeTypeCommunicate: false
    });
    closeModal();
    redirectMenu();
  }

  return (
    <Container>
      <div className={`menu-account ${hiddenPanel ? 'height-full' : ''}`}>
        <div
          className={`menu-account__left ${
            hiddenPanel && selectedLink > -1 ? 'hidden-panel' : ''
          }`}
        >
          <Card paddingHorizontal="0" paddingVertical="0" sticky>
            <div className={'udpate-user-data-menu'}>
              {mountLinksMenu()}
              <div className="pl-32 pr-32 width-full flex flex-column border-box">
                {!hiddenPanel ? mountButtonSave() : null}
                <Button
                  loading={loadingFullScreen}
                  className="mt-16 mb-24"
                  customColors="btn-white"
                  onClick={controlBackPage}
                >
                  <ButtonLabel>voltar</ButtonLabel>
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div
          className={`menu-account__right ${
            hiddenPanel && selectedLink > 0
              ? 'menu-account__right-btn-spacing'
              : ''
          }`}
        >
          {hiddenPanel ? selectedLink > -1 ? <Outlet /> : null : <Outlet />}
        </div>
        {hiddenPanel && (selectedLink == 0 || selectedLink == 1) ? (
          <div className="btn-update-data-mobile">
            <div>{mountButtonSave()}</div>
          </div>
        ) : null}
      </div>
      <Modal ref={modalRef}>
        <ModalIcon icon="triangleExclamation" />
        <ModalTitle>Alterações não salvas</ModalTitle>
        <ModalDescription>
          Parece que você tem alterações que não foram salvas, você
          <br className="breakline-desktop" /> tem certeza que{' '}
          <br className="breakline-mobile" /> deseja descartá-las?
        </ModalDescription>
        <div className="modal-actions">
          <Button
            className="mbi-0"
            full
            onClick={closeModalPendingDataToUpdate}
          >
            <ButtonLabel>descartar alterações</ButtonLabel>
          </Button>

          <Button
            className="mbi-0"
            full
            customColors="btn-white"
            onClick={closeModal}
          >
            <ButtonLabel>cancelar</ButtonLabel>
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
