import { IGetSessionsResponse } from '../../interfaces/user/Session';
import { GET, POST } from '../api';

const SSO_SERVICE = process.env.REACT_APP_BASE_URL_SSO_SERVICE;

export async function getListAllSessions(): Promise<IGetSessionsResponse> {
  const response = await GET(`${SSO_SERVICE}/api/v1/users/sessions`);
  return response.data;
}

export async function removeSessions(sessionsId: number[]) {
  const response = await POST(`${SSO_SERVICE}/api/v1/users/sessions/disable`, {
    sessions: sessionsId
  });

  return response.data;
}
