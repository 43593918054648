import './Menu.scss';
import Icon from '../Icon/Icon';
import Card from '../Card/Card';
import { useState } from 'react';

interface MenuProps {
  id?: string;
  label: string;
  children: React.ReactNode;
}

export default function Menu(props: MenuProps) {
  const [visible, setVisible] = useState(false);

  return (
    <div
      id={props?.id}
      className={'menu-button ' + (visible ? 'menu-active' : '')}
    >
      <button onClick={() => setVisible(!visible)}>
        {props.label}
        <Icon name={visible ? 'arrowUp' : 'arrowDown'} />
      </button>
      {visible ? (
        <div className="menu-button-items">
          <Card>{props.children}</Card>
        </div>
      ) : (
        false
      )}
    </div>
  );
}

// ex: Implementation
{
  /*
      <Menu label="menu">
        <MenuItem icon="newTab">item 1</MenuItem>
        <MenuItem icon="newTab">menu item</MenuItem>
      </Menu>
  */
}
