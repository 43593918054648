import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import AlertBox, { AlertBoxHandles } from '../components/AlertBox/AlertBox';
import { AttemptsTypeEnum } from '../enums/AttemptsTypeEnum';
import {
  IAccountControllerLogin,
  IResponseTokenSocial
} from '../interfaces/ISocial';

export interface ControllerFlowStepsContextData {
  socialData: IResponseTokenSocial;
  setSocialData: React.Dispatch<React.SetStateAction<IResponseTokenSocial>>;
  controllerViews: IAccountControllerLogin;
  setControllerViews: React.Dispatch<
    React.SetStateAction<IAccountControllerLogin>
  >;
  tokenSocial: string;
  setTokenSocial: React.Dispatch<React.SetStateAction<string>>;
  providerSocial: string;
  setProviderSocial: React.Dispatch<React.SetStateAction<string>>;
  socialWithoutEmail: boolean;
  setSocialWithoutEmail: React.Dispatch<React.SetStateAction<boolean>>;
  getOrigin: () => string;
  loadingFullScreen: boolean;
  setLoadingFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  statusAccountUser: IAccountControllerLogin;
  setStatusAccountUser: React.Dispatch<
    React.SetStateAction<IAccountControllerLogin>
  >;
  allowSkipValidateEmail: boolean;
  setAllowSkipValidateEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertBox: React.Dispatch<React.SetStateAction<AlertBoxMessage>>;
  showErrorMessageServer(): void;
  showCooldownErrorMessage(): void;
  notCreateAccount: boolean;
  setNotCreateAccount: (value: boolean) => any;
  validEmail: boolean;
  setValidEmail: React.Dispatch<React.SetStateAction<boolean>>;
  ctvCode: string | null;
  setCtvCode: React.Dispatch<React.SetStateAction<string | null>>;
  attempts: IAttempts;
  increaseAttemptByType: (type: AttemptsTypeEnum) => void;
}

type IAttempts = {
  [key in AttemptsTypeEnum]: number;
};

interface AlertBoxMessage {
  message: string | ReactNode;
  error: boolean;
  neutral?: boolean;
}

export const ControllerFlowStepsContext = createContext(
  {} as ControllerFlowStepsContextData
);

const ControllerFlowStepsProvider = ({ children }: any) => {
  const [socialData, setSocialData] = useState<IResponseTokenSocial>(
    {} as IResponseTokenSocial
  );
  const [controllerViews, setControllerViews] =
    useState<IAccountControllerLogin>({} as IAccountControllerLogin);
  const [tokenSocial, setTokenSocial] = useState('');
  const [providerSocial, setProviderSocial] = useState('');
  const [socialWithoutEmail, setSocialWithoutEmail] = useState(false);
  const [loadingFullScreen, setLoadingFullScreen] = useState(false);
  const [statusAccountUser, setStatusAccountUser] =
    useState<IAccountControllerLogin>({} as IAccountControllerLogin);
  const [allowSkipValidateEmail, setAllowSkipValidateEmail] = useState(true);
  const [alertBox, setAlertBox] = useState({} as AlertBoxMessage);
  const [notCreateAccount, setNotCreateAccount] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  const [ctvCode, setCtvCode] = useState<string | null>(null);
  const [attempts, setAttempts] = useState<IAttempts>({
    VALIDATE_EMAIL: 0,
    VALIDATE_NEW_EMAIL: 0,
    VALIDATE_EMAIL_RECOVERY_PASSWORD: 0,
    VALIDATE_PHONE: 0
  });

  const alertBoxRef = useRef<AlertBoxHandles>(null);
  function openAlert() {
    alertBoxRef.current?.openAlert();
  }

  useEffect(() => {
    if (alertBox.message) openAlert();
  }, [alertBox.message]);

  function showErrorMessageServer() {
    setAlertBox({
      message: (
        <span>
          Ops! Tivemos um problema.
          <br /> Tente novamente ou volte mais tarde.
        </span>
      ),
      error: true
    });
  }

  function showCooldownErrorMessage() {
    setAlertBox({
      message: (
        <span>
          Você atingiu o limite de tentativas.
          <br /> Por favor, volte mais tarde.
        </span>
      ),
      error: true
    });
  }

  function getOrigin() {
    let origin = '';
    const regexOrigin = /videos/g;
    const regexAppOrigin = /sbt/g;
    if (regexOrigin.test(window.location.pathname)) {
      origin = 'videos';
    } else if (regexAppOrigin.test(window.location.pathname)) {
      origin = 'sbt';
    } else {
      origin = '';
    }
    return origin;
  }

  function increaseAttemptByType(type: AttemptsTypeEnum) {
    setAttempts((prevState) => ({
      ...prevState,
      [type]: (prevState[type] || 0) + 1
    }));
  }

  return (
    <ControllerFlowStepsContext.Provider
      value={{
        socialData,
        setSocialData,
        controllerViews,
        setControllerViews,
        providerSocial,
        setProviderSocial,
        socialWithoutEmail,
        setSocialWithoutEmail,
        tokenSocial,
        setTokenSocial,
        getOrigin,
        loadingFullScreen,
        setLoadingFullScreen,
        statusAccountUser,
        setStatusAccountUser,
        allowSkipValidateEmail,
        setAllowSkipValidateEmail,
        setAlertBox,
        showErrorMessageServer,
        showCooldownErrorMessage,
        notCreateAccount,
        setNotCreateAccount,
        validEmail,
        setValidEmail,
        ctvCode,
        setCtvCode,
        attempts,
        increaseAttemptByType
      }}
    >
      {children}
      <AlertBox
        ref={alertBoxRef}
        text={alertBox.message}
        error={alertBox.error}
        neutral={alertBox.neutral}
      />
    </ControllerFlowStepsContext.Provider>
  );
};

export const useControllerFlowStepsContext = () =>
  useContext(ControllerFlowStepsContext);

export default ControllerFlowStepsProvider;
