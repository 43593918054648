import moment from 'moment';
import {
  CTV_CODE_COOKIE,
  LINK_SSO_NATIVE,
  LINK_SSO_SBT,
  LINK_SSO_VIDEOS,
  ORIGIN_DOMAIN_MAIS_SBT,
  ORIGIN_DOMAIN_SBT_GAMES,
  ORIGIN_DOMAIN_SBT_NEWS,
  ORIGIN_DOMAIN_SBT_SITE,
  ORIGIN_DOMAIN_SBT_TV,
  ORIGIN_DOMAIN_SBT_VIDEOS,
  WEBVIEW_COOKIE
} from '../data/Constants';
moment.locale('pt-br');
import 'moment/locale/pt-br';
import {
  getCookie,
  deleteCookie,
  setCookie
} from '../services/cookieController';
import { readJwt } from '../services/tokenValidation';
import { KEY_SESSION } from '../hooks/AuthContext';
export const KEY_SESSION_ORIGIN = process.env
  .REACT_APP_KEY_SESSION_ORIGIN as string;

export function testFormatDate(date: string) {
  if (!date) return false;
  const regexSplit = /\d{2}\/\d{2}\/\d{4}/g;

  return regexSplit.test(date);
}

export function convertToMomentDate(date: string) {
  const [day, month, year] = date.split('/');
  return moment(`${year}-${month}-${day}`);
}

export function accDate(
  date: string,
  acc: number,
  kind: 'days' | 'months' | 'year'
) {
  return moment(date).add(acc, kind);
}

export function convertToDDMMYY(date: string) {
  return moment(date).format('LL');
}

export function testDateIsValid(date: string) {
  if (!testFormatDate(date)) return false;
  const dateValue = convertToMomentDate(date);
  return dateValue.isValid();
}

export function testIsEqualOrGreat18Years(date: string) {
  if (!testFormatDate(date)) return false;
  const currentDate = moment();
  const dateValue = convertToMomentDate(date);
  return currentDate.diff(dateValue, 'years') >= 18;
}

export function testIsGreatHundredAge(date: string) {
  if (!testFormatDate(date)) return false;
  const currentDate = moment();
  const dateValue = convertToMomentDate(date);
  return currentDate.diff(dateValue, 'years') > 100;
}

export function validatedCPF(value: string) {
  if ((value && value.replace(/\D/g, '') == '00000000000') || !value)
    return false;
  let soma = 0;
  let resto;
  value = value.replace(/\D/g, '');
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(value.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(value.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(value.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(value.substring(10, 11))) return false;
  return true;
}

let timeout: any;
export function debounceInput(callback?: any, timer?: number, param?: any) {
  const countTime = timer || 400;
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (callback) callback(param);
  }, countTime);
}

export function clearSpecialCharactersFromNumber(value: string) {
  if (!value) return value;

  return value.replace(/\D/g, '');
}

export function formatDateToSend(value: string) {
  if (!value) return value;

  const [day, month, year] = value.split('/');
  return `${year}-${month}-${day}`;
}

export function getOriginDomain() {
  const originSet = getCookie(KEY_SESSION_ORIGIN);
  if (originSet) return originSet;
  let originDomain = ORIGIN_DOMAIN_SBT_SITE;
  const regexOrigin = /videos/g;
  if (regexOrigin.test(window.location.pathname)) {
    originDomain = ORIGIN_DOMAIN_SBT_VIDEOS;
  }
  return originDomain;
}

export function getUrlSignIn() {
  const regexOrigin = /videos/g;
  const regexAppOrigin = /sbt/g;
  if (regexOrigin.test(window.location.pathname)) {
    return LINK_SSO_VIDEOS;
  } else if (regexAppOrigin.test(window.location.pathname)) {
    return LINK_SSO_SBT;
  }
  return LINK_SSO_NATIVE;
}

export function hasPhoneNumbersInvalid(value: string) {
  if (!value) return false;
  value = value.replace(/\D/g, '');

  const numberInvalid = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999'
  ];

  return numberInvalid.findIndex((number) => number === value) > -1;
}

export function formatCep(value: string) {
  if (!value) return value;
  value = String(value).replace(/-/g, '').replace(/\./g, '').replace(/ /g, '');
  return value.substring(0, 5) + '-' + value.substring(5, 8);
}

export function formatCpf(value: string) {
  if (!value) return value;
  return (
    value.substring(0, 3) +
    '.' +
    value.substring(3, 6) +
    '.' +
    value.substring(6, 9) +
    '-' +
    value.substring(9, 11)
  );
}

export function formatDateToDDMMYYYY(value: any) {
  if (!value) return value;
  const [year, month, day] = value.split('-');
  return moment(`${year}-${month}-${day}`).format('L');
}

export function formatPhone(value: string) {
  if (!value) return value;

  return (
    '+55 (' +
    value.substring(0, 2) +
    ') ' +
    value.substring(2, 7) +
    '-' +
    value.substring(7, 11)
  );
}

export function formatPhoneWithPrefix(value: string) {
  if (!value) return value;

  return (
    '(' +
    value.substring(0, 2) +
    ') ' +
    value.substring(2, 7) +
    '-' +
    value.substring(7, 11)
  );
}

export async function compareForm(atual: any, novo: any) {
  let equal = true;
  const response: any = { fieldsNotEqual: [] };
  for (const [key, value] of Object.entries(atual)) {
    for (const [key2, value2] of Object.entries(novo)) {
      if (key == key2 && value != value2) {
        response.fieldsNotEqual.push({
          field: key,
          currentValue: value,
          newValue: value2
        });
        equal = false;
      }
    }
  }
  return await { ...response, equal };
}

export function convertToBase64(text: string) {
  if (!text) return text;

  return Buffer.from(text, 'binary').toString('base64');
}

export function isWebview() {
  const webview = getCookie(WEBVIEW_COOKIE);
  return webview === 'true';
}

export function isSignUp(origin: string) {
  return (
    origin === 'signup' || origin === 'sbt/signup' || origin === 'videos/signup'
  );
}

export function shouldOpenMenuAccount(
  redirectURL: string,
  responsePendingData: any
) {
  if (!redirectURL) redirectURL = getCookie('redirect') || '';
  const shouldOpenMenu =
    !window.location.pathname.includes('videos') &&
    !window.location.pathname.includes('sbt') &&
    !redirectURL &&
    responsePendingData?.emailValidated;

  if (shouldOpenMenu) deleteCookie('redirect');
  return shouldOpenMenu;
}

export function isValidCustomOrigin(origin: string) {
  return [
    ORIGIN_DOMAIN_SBT_GAMES,
    ORIGIN_DOMAIN_SBT_NEWS,
    ORIGIN_DOMAIN_SBT_TV,
    ORIGIN_DOMAIN_MAIS_SBT
  ].includes(origin);
}

export function setOriginDomainCustom() {
  const query = new URLSearchParams(location.search);
  const origin = query.get('origin');
  const regexAppOrigin = /sbt/g;
  if (
    origin &&
    isValidCustomOrigin(origin) &&
    regexAppOrigin.test(window.location.pathname)
  ) {
    const appRedirect = query.get('app_redirect');
    if (appRedirect) setCookie('app_redirect', appRedirect);

    setCookie(KEY_SESSION_ORIGIN, origin);
    if (query.get('webview') && query.get('webview') === 'true')
      setCookie(WEBVIEW_COOKIE, 'true');
  }
}

export function checkSocialName(socialName: string): boolean {
  socialName = socialName.replace(/[^a-zA-ZÀ-ú ]/g, '');
  return !!(
    socialName.trim().length < 2 ||
    !socialName.trim().split(' ')[0] ||
    !socialName.trim().split(' ')[1] ||
    socialName.trim().split(' ')[0].length < 2 ||
    socialName.trim().split(' ')[1].length < 2
  );
}

export function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function pushGTMDataLayer(token?: string) {
  const decodeToken: any = readJwt(token || getCookie(KEY_SESSION) || '');
  const userid = decodeToken?.user?.id;

  try {
    window.dataLayer.push({
      user_id: { userid }
    });
  } catch (e) {
    console.error(e);
  }
}

export function handleOAuthRedirectURI(
  provider: 'apple' | 'facebook' | 'google'
): string {
  return `${window.location.origin}/callback/oauth/${provider}`;
}

export function shouldOpenOAuthWithRedirect(redirect: string): boolean {
  const query = new URLSearchParams(location.search);

  const redirectURL =
    query?.get('redirect') || getCookie('redirect') || redirect;
  const origin = query?.get('origin') || getCookie(KEY_SESSION_ORIGIN);
  const regexAppOrigin = /sbt/g;

  const validRedirectURLs = [
    'sbt://mais.sbt.com',
    'sbt://web.stg.maissbt.com',
    'sbt://web.uat.maissbt.com'
  ];

  // Now: Just +SBT Android should open in redirect mode
  return (
    validRedirectURLs?.some((url) => redirectURL?.includes(url)) &&
    origin === ORIGIN_DOMAIN_MAIS_SBT &&
    regexAppOrigin.test(window.location.pathname)
  );
}

export function isEdgeBrowser() {
  return /Edg/.test(navigator.userAgent);
}

export function isEdgeOnIos(): boolean {
  const isIos = iOS();
  const isEdge = isEdgeBrowser();

  return isIos && isEdge;
}

export function convertSecondsToMMSS(seconds: number) {
  // Calculate the minutes and seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format minutes and seconds to be two digits
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  // Combine them in mm:ss format
  return `${formattedMinutes}:${formattedSeconds}`;
}

export function extractCtvCodeFromCookie(
  setCtvCode: (value: React.SetStateAction<string | null>) => void
): string {
  const ctvCode = getCookie(CTV_CODE_COOKIE);
  if (ctvCode) {
    setCtvCode(ctvCode);
    deleteCookie(CTV_CODE_COOKIE);
    return ctvCode;
  }
  return '';
}
