import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertBox, {
  AlertBoxHandles
} from '../../../../components/AlertBox/AlertBox';
import Button from '../../../../components/Button/Button';
import ButtonLabel from '../../../../components/Button/ButtonLabel';
import FormFieldTokenNumber from '../../../../components/FormField/FormFieldTokenNumber';
import {
  CODE_INCORRECT_TOKEN,
  DEFAULT_API_COOLDOWNS
} from '../../../../data/Constants';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import {
  createTokenValidateNewEmail,
  validateCodeFromEmail
} from '../../../../services/tokenValidation';
import { ErrorMessages } from '../../../../data/ErrorMessages';
import { updateUserEmail } from '../../../../services/user';
import { SendCodeToEmailEventsEnum } from '../../../../enums/SendCodeToEmailEventsEnum';
import { refreshToken } from '../../../../services/api';
import { getCookie } from '../../../../services/cookieController';
import { KEY_REFRESH_SESSION } from '../../../../hooks/AuthContext';
import { AttemptsTypeEnum } from '../../../../enums/AttemptsTypeEnum';
import { convertSecondsToMMSS } from '../../../../utils/GenericFunctions';

export interface EditEmailCheckTokenProps {
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
}

export default function EditEmailCheckToken({
  previousPath,
  handleChangeStep
}: EditEmailCheckTokenProps) {
  const {
    setAlertBox,
    showCooldownErrorMessage,
    showErrorMessageServer,
    attempts,
    increaseAttemptByType
  } = useControllerFlowStepsContext();
  const { getValues, setUpdatedData } = useUpdateUserDataContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const [fieldValidate, setValueFieldValidate] = useState({
    value: '',
    isValid: false
  });
  const [errorToken, setErrorToken] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [runTime, setRunTime] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    timeOutResend(attempts?.VALIDATE_NEW_EMAIL);
    setShowButton(true);
    setGoBackNavigate({ path: '', param: {} });
  }, []);

  function timeOutResend(intervalPos: number) {
    setRunTime(true);
    let initSeconds = DEFAULT_API_COOLDOWNS[intervalPos] || 60;
    setSeconds(initSeconds);
    const interval = setInterval(() => {
      if (initSeconds == 0) {
        clearInterval(interval);
        setSeconds(0);
        setRunTime(false);
      } else {
        setSeconds(--initSeconds);
      }
    }, 1000);
  }

  useEffect(() => {
    navigate('../token', { relative: 'path' });
  }, [navigate]);

  window.onpopstate = () => {
    previousStep();
  };

  function previousStep() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    }
  }

  async function checkToken() {
    const email = getValues('email');
    if (email && fieldValidate.value) {
      setLoading(true);
      try {
        await validateCodeFromEmail(
          email,
          fieldValidate.value,
          SendCodeToEmailEventsEnum.CONFIRM_NEW_EMAIL,
          ErrorMessages.CODE_EMAIL_NEW_EMAIL
        );

        const identityCode = getValues('code') || '';

        await updateUserEmail({
          newEmail: email,
          identityVerifierCode: identityCode,
          newEmailCode: fieldValidate.value
        });
        await refreshToken(getCookie(KEY_REFRESH_SESSION) || '');
        setUpdatedData(true);
        setAlertBox({
          message: 'e-mail alterado com sucesso',
          error: false
        });
        navigate('/menu-de-conta/atualizar-dados', { replace: true });
      } catch (error: any) {
        if (error?.code === CODE_INCORRECT_TOKEN) {
          setErrorToken(true);
        } else {
          console.error(error);
          showErrorMessageServer();
        }
        setResendingCode(false);
        setLoading(false);
      }
    }
  }

  async function resendCode() {
    setErrorToken(false);
    setResendingCode(true);
    await postCreateToken();
    increaseAttemptByType(AttemptsTypeEnum.VALIDATE_NEW_EMAIL);
    timeOutResend(attempts.VALIDATE_PHONE + 1);
  }

  async function postCreateToken() {
    const newEmail = getValues('email');
    setLoading(true);
    try {
      await createTokenValidateNewEmail(newEmail);
      openAlert();
      setLoading(false);
    } catch (error: any) {
      if (error?.statusCode === 429) showCooldownErrorMessage();
      else showErrorMessageServer();
    }
  }

  const alertBoxRef = useRef<AlertBoxHandles>(null);
  function openAlert() {
    alertBoxRef.current?.openAlert();
  }

  return (
    <>
      <div className="flex flex-column">
        <h2 className="color-text-default">confirmar alteração</h2>
        <h6 className="mti-8 mbi-8 color-text-default">
          Para confirmar sua alteração de e-mail informe o código de validação.
        </h6>
        <span className="md mt-4 mb-24">
          digite o código recebido por <b>e-mail</b> no endereço
          <br />
          <span className="md-bold-700">{getValues('email')}</span>
        </span>

        <FormFieldTokenNumber
          fieldValidate={fieldValidate}
          errorToken={errorToken}
          setState={setValueFieldValidate}
          resendingCode={resendingCode}
        />

        <div className="flex flex-column mt-16">
          <Button
            id="update-email-confirm"
            className="mt-8 mbi-0"
            full
            onClick={checkToken}
            loading={loading}
            disabled={!fieldValidate.isValid}
          >
            <ButtonLabel loading={loading}>confirmar código</ButtonLabel>
          </Button>
          <Button
            id="update-email-resend-token"
            className="mt-16 mbi-0"
            customColors="btn-outline"
            full
            onClick={resendCode}
            loading={loading}
            disabled={runTime}
          >
            <ButtonLabel>
              reenviar código {convertSecondsToMMSS(seconds)}
            </ButtonLabel>
          </Button>
          <Button
            id="update-email-confirm-code-goback"
            className="mt-16"
            customColors="btn-white"
            full
            onClick={previousStep}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
      <AlertBox ref={alertBoxRef} text="código enviado com sucesso" />
    </>
  );
}
