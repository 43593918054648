import Container from '../../components/Container/Container';
import Card from '../../components/Card/Card';
import './MenuAccount.scss';
import Icon from '../../components/Icon/Icon';
import FormFieldToggle from '../../components/FormField/FormFieldToggle';
import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../../hooks/AuthContext';
import Modal, { ModalHandles } from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalDescription from '../../components/Modal/ModalDescription';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import { checkUserHasPassword, logoutSSO } from '../../services/user';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import DisableAccountProvider from '../../data/DisableAccount/DisableAccountContext';
import { sessionUserInfo } from '../../services/queryController';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { initApple, initFB } from '../../services/socialNetWorkController';
import {
  SocialActionEnum,
  useControllerSocialMediaContext
} from '../../hooks/ControllerSocialMedia';
import { PROVIDER_SOCIAL } from '../../data/Constants';
import { unlinkSocialAccount } from '../../services/accountController';
import ModalIcon from '../../components/Modal/ModalIcon';
import { useControlNavBarContext } from '../../hooks/ControlNavBar';
import { isWebview } from '../../utils/GenericFunctions';
import { useGoogleLogin } from 'react-google-login';
import { SentryContextsEnum } from '../../utils/Sentry';
import { IUser } from '../../interfaces/user';

const REACT_APP_APPID_GOOGLE = process.env.REACT_APP_APPID_GOOGLE || '';
const REACT_APP_SCOPE_GOOGLE_PROFILE =
  process.env.REACT_APP_SCOPE_GOOGLE_PROFILE;
const REACT_APP_SCOPE_GOOGLE_EMAIL = process.env.REACT_APP_SCOPE_GOOGLE_EMAIL;

export default function AccountMenu() {
  const {
    loginFace,
    loginApple,
    googleHandleCredentialResponse,
    observableChangeStatus,
    setObservableChangeStatus
  } = useControllerSocialMediaContext();
  const { setLoadingFullScreen, setAlertBox, showErrorMessageServer } =
    useControllerFlowStepsContext();
  const {
    user,
    setUser,
    setOpenSession,
    checkAuth,
    accountDisabled,
    setAccountDisabled,
    clearSession
  } = useAuthContext();
  const { setShowButton } = useControlNavBarContext();
  const [loadingLogout, setLoadingLogout] = useState(false);
  const [checkFace, setCheckFace] = useState(false);
  const [checkGoogle, setCheckGoogle] = useState(false);
  const [checkApple, setCheckApple] = useState(false);
  const [numberSocialAccountActive, setNumberSocialAccountActive] = useState(0);
  const [showModalCreatePassword, setShowModalCreatePassword] = useState(false);
  const [providerModal, setProviderModal] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPath = location.pathname;

  useEffect(() => {
    setLoadingFullScreen(true);
    setShowButton(false);
    getUserInfos();
    initFB();
    initApple('');
  }, []);

  const { signIn } = useGoogleLogin({
    clientId: REACT_APP_APPID_GOOGLE,
    scope: [REACT_APP_SCOPE_GOOGLE_EMAIL, REACT_APP_SCOPE_GOOGLE_PROFILE].join(
      ' '
    ),
    onSuccess: (res: any) =>
      googleHandleCredentialResponse(res, SocialActionEnum.CONNECT_ACCOUNT),
    onFailure: (res) => console.log(res),
    cookiePolicy: 'single_host_origin'
  });

  useEffect(() => {
    if (observableChangeStatus.observing) {
      if (observableChangeStatus.success == 'REJECT') {
        updateChangeStatusSocialMedia(observableChangeStatus.provider);
        setDefaultObservableChangeStatus();
      }
      if (observableChangeStatus.success == 'OK') {
        setDefaultObservableChangeStatus();
      }
    }
  }, [observableChangeStatus.success]);

  function setDefaultObservableChangeStatus() {
    setObservableChangeStatus({
      observing: false,
      success: '',
      provider: ''
    });
  }

  function updateChangeStatusSocialMedia(provider: string) {
    switch (provider) {
      case PROVIDER_SOCIAL.FACEBOOK: {
        changeStatusFacebook(false);
        break;
      }
      case PROVIDER_SOCIAL.APPLE: {
        changeStatusApple(false);
        break;
      }
      case PROVIDER_SOCIAL.GOOGLE: {
        changeStatusGoogle(false);
        break;
      }
    }
  }

  async function getUserInfos() {
    try {
      const token = searchParams.get('token') || searchParams.get('jwt');
      checkAuth(token || undefined).user;
      const userData = await sessionUserInfo();
      setAccountDisabled(!userData.accountActivated);
      setUser(userData);
      markCheckLoginSocial(userData);
    } catch (_) {
      showErrorMessageServer();
    } finally {
      setLoadingFullScreen(false);
    }
  }

  function markCheckLoginSocial(data: IUser) {
    setCheckGoogle(!!data.googleId);
    setCheckFace(!!data.faceId);
    setCheckApple(!!data.appleId);
    markNumberSocialAccountActive(data);
  }

  function markNumberSocialAccountActive(data: any) {
    let count = 0;
    count = data.googleId ? count + 1 : count;
    count = data.faceId ? count + 1 : count;
    count = data.appleId ? count + 1 : count;
    setNumberSocialAccountActive(count);
  }

  const modalRef = useRef<ModalHandles>(null);
  function openModal() {
    modalRef.current?.openModal();
  }

  function logoutModal() {
    openModal();
  }

  function closeLogoutModal() {
    modalRef.current?.closeModal();
  }

  async function confirmLogout() {
    setLoadingLogout(true);
    try {
      await logoutSSO();
      setOpenSession(false);
      clearSession();
      navigate('/', { replace: true });
    } catch (_) {
      setOpenSession(false);
      clearSession();
      navigate('/', { replace: true });
      setLoadingLogout(false);
    }
  }

  async function getUserHasPassword() {
    setLoadingFullScreen(true);
    const response = await checkUserHasPassword(
      user.email,
      SentryContextsEnum.ACCOUNT_MENU_MERGE
    );
    return response;
  }

  function getUserName() {
    if (!user.hasSocialName) return user.userName;
    return user.socialName;
  }

  async function setUnlinkSocialAccount(
    email: string,
    provider: string,
    setCheck: any
  ) {
    try {
      await unlinkSocialAccount(email, provider);
      setAlertBox({
        message: `sua conta d${provider === 'APPLE' ? 'a' : 'o'} ${
          provider.charAt(0).toUpperCase() + provider.slice(1).toLowerCase()
        } foi desconectada`,
        error: false
      });
    } catch (error) {
      console.error(error);
      setAlertBox({
        message:
          'Não foi possível desconectar sua conta. Tente novamente mais tarde',
        error: true
      });
      setCheck(true);
    }
  }

  async function changeStatusFacebook(status: boolean) {
    if (!checkFace) {
      setObservableChangeStatus({
        provider: PROVIDER_SOCIAL.FACEBOOK,
        observing: true,
        success: 'LOADING'
      });
      loginFace(SocialActionEnum.CONNECT_ACCOUNT);
    } else if (user.faceId && checkFace) {
      try {
        const { hasPassword } = await getUserHasPassword();
        if (!hasPassword && numberSocialAccountActive === 1) {
          setShowModalCreatePassword(true);
          setProviderModal(PROVIDER_SOCIAL.FACEBOOK);
          openModal();
        } else {
          setUnlinkSocialAccount(
            user.email,
            PROVIDER_SOCIAL.FACEBOOK,
            setCheckFace
          );
          setNumberSocialAccountActive((state) => state - 1);
        }
      } catch (error) {
        console.error(error);
        showErrorMessageServer();
      } finally {
        setLoadingFullScreen(false);
        setCheckFace(status);
      }
    }
  }

  async function changeStatusGoogle(status: boolean) {
    if (!checkGoogle) {
      setObservableChangeStatus({
        provider: PROVIDER_SOCIAL.GOOGLE,
        observing: true,
        success: 'LOADING'
      });
      signIn();
    } else if (user.googleId && checkGoogle) {
      try {
        const { hasPassword } = await getUserHasPassword();
        if (!hasPassword && numberSocialAccountActive === 1) {
          setShowModalCreatePassword(true);
          setProviderModal(PROVIDER_SOCIAL.GOOGLE);
          openModal();
        } else {
          setUnlinkSocialAccount(
            user.email,
            PROVIDER_SOCIAL.GOOGLE,
            setCheckGoogle
          );
          setNumberSocialAccountActive((state) => state - 1);
        }
      } catch (error) {
        console.error(error);
        showErrorMessageServer();
      } finally {
        setLoadingFullScreen(false);
        setCheckGoogle(status);
      }
    }
  }

  async function changeStatusApple(status: boolean) {
    if (!checkApple) {
      setObservableChangeStatus({
        provider: PROVIDER_SOCIAL.APPLE,
        observing: true,
        success: 'LOADING'
      });
      loginApple(SocialActionEnum.CONNECT_ACCOUNT);
    } else if (user.appleId && checkApple) {
      try {
        const { hasPassword } = await getUserHasPassword();
        if (!hasPassword && numberSocialAccountActive === 1) {
          setShowModalCreatePassword(true);
          setProviderModal(PROVIDER_SOCIAL.APPLE);
          openModal();
        } else {
          setUnlinkSocialAccount(
            user.email,
            PROVIDER_SOCIAL.APPLE,
            setCheckApple
          );
          setNumberSocialAccountActive((state) => state - 1);
        }
      } catch (error) {
        console.error(error);
        showErrorMessageServer();
      } finally {
        setLoadingFullScreen(false);
        setCheckApple(status);
      }
    }
  }

  useEffect(() => {
    if (
      observableChangeStatus.observing &&
      observableChangeStatus.success === 'OK'
    ) {
      if (observableChangeStatus.provider === PROVIDER_SOCIAL.FACEBOOK)
        setCheckFace(true);
      else if (observableChangeStatus.provider === PROVIDER_SOCIAL.GOOGLE)
        setCheckGoogle(true);
      else if (observableChangeStatus.provider === PROVIDER_SOCIAL.APPLE)
        setCheckApple(true);
    }
  }, [observableChangeStatus]);

  function notCreatePassword() {
    modalRef.current?.closeModal();
    setShowModalCreatePassword(false);
    setAlertBox({
      message: 'não é possível desconectar sua conta social',
      error: true
    });
    switch (providerModal) {
      case PROVIDER_SOCIAL.FACEBOOK: {
        setCheckFace(true);
        break;
      }
      case PROVIDER_SOCIAL.GOOGLE: {
        setCheckGoogle(true);
        break;
      }
      case PROVIDER_SOCIAL.APPLE: {
        setCheckApple(true);
        break;
      }
    }
  }

  function redirectToCreatePassword() {
    navigate('/menu-de-conta/criar-senha', {
      state: { provider: providerModal }
    });
  }

  function getFirstLetterName() {
    const name = getUserName();
    return name ? name[0].toUpperCase() : '';
  }

  function mountModalLogout() {
    return (
      <>
        <ModalTitle>tem certeza que quer sair da Conta SBT?</ModalTitle>
        <ModalDescription>
          Sua sessão será encerrada apenas neste aparelho
        </ModalDescription>
        <div className="modal-actions">
          <Button
            id="confirm-logout"
            className="mbi-0"
            full
            onClick={confirmLogout}
            disabled={loadingLogout}
          >
            <ButtonLabel loading={loadingLogout}>sair da conta</ButtonLabel>
          </Button>

          <Button
            className="mbi-0"
            full
            customColors="btn-white"
            onClick={closeLogoutModal}
          >
            <ButtonLabel>não sair da conta</ButtonLabel>
          </Button>
        </div>
      </>
    );
  }

  function mountModalCreatePassword() {
    return (
      <>
        <ModalIcon icon="triangleExclamation" />
        <ModalTitle>crie uma senha para sua Conta SBT</ModalTitle>
        <ModalDescription>
          Sua Conta SBT não possui uma senha. Para remover todas suas contas
          conectadas, você precisa criar uma senha.
        </ModalDescription>
        <div className="modal-actions">
          <Button className="mbi-0" full onClick={redirectToCreatePassword}>
            <ButtonLabel>criar senha</ButtonLabel>
          </Button>

          <Button
            className="mbi-0"
            full
            customColors="btn-white"
            onClick={notCreatePassword}
          >
            <ButtonLabel>cancelar</ButtonLabel>
          </Button>
        </div>
      </>
    );
  }

  function openWebviewSocialButton() {
    setAlertBox({
      message: 'acesse conta.sbt.com.br para gerenciar suas contas conectadas.',
      error: false,
      neutral: true
    });
  }

  return (
    <Container>
      <div className="menu-account">
        <div
          className={`menu-account__left ${
            currentPath != '/menu-de-conta' ? 'hidden-mobile-panel' : ''
          }`}
        >
          <Card>
            <div
              className={
                'menu-account__infos-user ' +
                (accountDisabled ? ' account-disabled' : '')
              }
            >
              <div className="menu-account__avatar">
                <span>{getFirstLetterName()}</span>
              </div>
              <h2 className="color-text-default mt-8">{getUserName()}</h2>
              <span className="lg color-text-default mt-4">{user.email}</span>
              {accountDisabled || isWebview() ? null : (
                <h5
                  id="logout-btn"
                  onClick={logoutModal}
                  className="link-intern-text text-purple mt-18"
                >
                  sair
                </h5>
              )}
            </div>

            {accountDisabled ? null : (
              <div className="menu-account__infos-sociais mt-8">
                <h3 className="color-text-default mt-8">contas conectadas</h3>
                <span className="md color-text-default mt-8">
                  Conecte contas do Facebook, Google e<br /> Apple para usá-las
                  para fazer login{' '}
                </span>

                <div
                  id="infos-social-list"
                  className="menu-account__infos-sociais-list mt-32"
                >
                  <div className="menu-account__infos-sociais-item">
                    <div className="mr-16">
                      <Icon name="logoFace" />
                    </div>
                    <div className="mr-32">
                      <span className="menu-account__infos-sociais-item-text">
                        Facebook
                      </span>
                    </div>
                    <div>
                      <FormFieldToggle
                        id="facebook"
                        value={'facebook'}
                        checked={checkFace}
                        callbackChange={
                          isWebview()
                            ? openWebviewSocialButton
                            : changeStatusFacebook
                        }
                      />
                    </div>
                  </div>
                  <div className="menu-account__infos-sociais-item mt-3">
                    <div className="mr-16">
                      <Icon name="logoGoogle" />
                    </div>
                    <div className="mr-32">
                      <span className="menu-account__infos-sociais-item-text">
                        Google
                      </span>
                    </div>
                    <div>
                      <FormFieldToggle
                        id="google"
                        value={'google'}
                        checked={checkGoogle}
                        callbackChange={
                          isWebview()
                            ? openWebviewSocialButton
                            : changeStatusGoogle
                        }
                      />
                    </div>
                  </div>
                  <div className="menu-account__infos-sociais-item mt-3">
                    <div className="mr-16">
                      <Icon name="logoApple" />
                    </div>
                    <div className="mr-32">
                      <span className="menu-account__infos-sociais-item-text">
                        Apple
                      </span>
                    </div>
                    <div>
                      <FormFieldToggle
                        id="apple"
                        value={'apple'}
                        checked={checkApple}
                        callbackChange={
                          isWebview()
                            ? openWebviewSocialButton
                            : changeStatusApple
                        }
                      />
                    </div>
                  </div>
                </div>
                {isWebview() && (
                  <div className="menu-account__webview-info mt-18">
                    <div className="mr-8">
                      <Icon name="info" />
                    </div>
                    <div>
                      Acesse este menu em <b>conta.sbt.com.br</b> para conectar
                      e desconectar contas do Google, Facebook e Apple.
                    </div>
                  </div>
                )}
              </div>
            )}
          </Card>
        </div>
        <div id="menu-account-right" className="menu-account__right">
          <DisableAccountProvider>
            <Outlet />
          </DisableAccountProvider>
        </div>
      </div>
      <Modal ref={modalRef}>
        {showModalCreatePassword
          ? mountModalCreatePassword()
          : mountModalLogout()}
      </Modal>
    </Container>
  );
}
