import { useEffect } from 'react';
import {
  SocialActionEnum,
  useControllerSocialMediaContext
} from '../../hooks/ControllerSocialMedia';
import { initApple } from '../../services/socialNetWorkController';
import Icon from '../Icon/Icon';
import './SocialMedia.scss';
import { shouldOpenOAuthWithRedirect } from '../../utils/GenericFunctions';
import { useAuthContext } from '../../hooks/AuthContext';
import { setCookie } from '../../services/cookieController';

interface ButtonAppleProps {
  action?: SocialActionEnum;
}

export default function ButtonApple({ action }: ButtonAppleProps) {
  const { loginApple } = useControllerSocialMediaContext();
  const { redirectURL } = useAuthContext();

  useEffect(() => {
    initApple(redirectURL);
  }, []);

  return (
    <>
      <div
        style={{ display: 'none' }}
        id="appleid-signin"
        data-color="black"
        data-border="true"
        data-type="sign in"
      ></div>
      <button
        id="apple-btn"
        data-testid="btn-apple"
        aria-label="Entrar com Apple"
        type="button"
        onClick={() => {
          if (redirectURL) setCookie('redirect', redirectURL);
          loginApple(action, shouldOpenOAuthWithRedirect(redirectURL));
        }}
      >
        <Icon name="logoApple" />
      </button>
    </>
  );
}
